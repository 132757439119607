import React, { useState, useEffect } from 'react';
import Select from 'react-select';

// Mock data for sports (you can update this list as needed)
const sportsData = [
  { Name: 'Foot à 5', Keywords: ['football à 5', 'five', 'soccer à 5', 'futsal'] },
  { Name: 'Padel', Keywords: ['padel tennis'] },
  { Name: 'Football', Keywords: ['soccer', 'foot'] },
  { Name: 'Basketball', Keywords: ['basket'] },
  { Name: 'Tennis', Keywords: ['tennis'] },
  { Name: 'Cricket', Keywords: ['cricket'] },
  { Name: 'Baseball', Keywords: ['baseball'] },
  { Name: 'Rugby', Keywords: ['rugby à XV', 'rugby à XIII'] },
  { Name: 'Hockey sur glace', Keywords: ['hockey', 'hockey glace'] },
  { Name: 'Hockey sur gazon', Keywords: ['hockey gazon', 'hockey herbe'] },
  { Name: 'Golf', Keywords: ['golf'] },
  { Name: 'Volleyball', Keywords: ['volley', 'ballon-volley'] },
  { Name: 'Tennis de table', Keywords: ['ping-pong', 'table tennis'] },
  { Name: 'Badminton', Keywords: ['badminton'] },
  { Name: 'Handball', Keywords: ['handball'] },
  { Name: 'Natation', Keywords: ['swimming', 'piscine'] },
  { Name: 'Cyclisme', Keywords: ['vélo', 'bicyclette', 'course cycliste'] },
  { Name: 'Course à pied', Keywords: ['running', 'jogging', 'course'] },
  { Name: 'Escalade', Keywords: ['grimpe', 'escalade en salle', 'escalade en falaise'] },
  { Name: 'Boxe', Keywords: ['boxe anglaise', 'boxing'] },
  { Name: 'Judo', Keywords: ['judo', 'art martial'] },
  { Name: 'Karate', Keywords: ['karaté', 'art martial'] },
  { Name: 'Taekwondo', Keywords: ['taekwondo', 'art martial'] },
  { Name: 'Escrime', Keywords: ['fencing', 'épée', 'sabre'] },
  { Name: 'Ski', Keywords: ['ski alpin', 'ski de fond'] },
  { Name: 'Snowboard', Keywords: ['planche à neige', 'snowboarding'] },
  { Name: 'Surf', Keywords: ['surf', 'planche de surf'] },
  { Name: 'Aviron', Keywords: ['rowing'] },
  { Name: 'Canoë-kayak', Keywords: ['canoë', 'kayak'] },
  { Name: 'Planche à voile', Keywords: ['windsurfing', 'windsurf'] },
  { Name: 'Équitation', Keywords: ['cheval', 'monte à cheval'] },
  { Name: 'Triathlon', Keywords: ['triathlon'] },
  { Name: 'Marathon', Keywords: ['course de fond', 'marathon'] },
  { Name: 'Crossfit', Keywords: ['cross-training', 'fitness'] },
  { Name: 'Athlétisme', Keywords: ['course', 'lancer', 'saut'] },
  { Name: 'Patinage artistique', Keywords: ['figure skating', 'patin'] },
  { Name: 'Water-polo', Keywords: ['water polo'] },
  { Name: 'Danse', Keywords: ['danse sportive', 'danse classique', 'danse contemporaine'] },
  { Name: 'Zumba', Keywords: ['zumba', 'danse fitness'] },
  { Name: 'Pilates', Keywords: ['pilates', 'exercice'] },
  { Name: 'Yoga', Keywords: ['yoga', 'méditation'] }
];

function SportSelect({ value, onChange, isDisabled = false, placeholder = 'Select a sport', customStyles = {} }) {
  const [sportOptions, setSportOptions] = useState([]);
  const [filteredSports, setFilteredSports] = useState([]);

  // Load sport options on component mount
  useEffect(() => {
    const sports = sportsData.map((sport) => ({
      value: sport.Name,
      label: sport.Name,
      keywords: sport.Keywords || [], // Add keywords if available
    }));
    setSportOptions(sports);
    setFilteredSports(sports); // Initially set filteredSports to all sports
  }, []);

  // Lazy load sport options by filtering based on input value
  const loadSportOptions = (inputValue) => {
    if (inputValue) {
      const lowerInput = inputValue.toLowerCase();

      const filtered = sportOptions.filter((sport) => {
        // Check if the input matches the sport name
        const nameMatch = sport.label.toLowerCase().includes(lowerInput);

        // Check if the input matches any of the keywords
        const keywordMatch = sport.keywords.some((keyword) => keyword.toLowerCase().includes(lowerInput));

        // Return true if either the name or any keyword matches the input
        return nameMatch || keywordMatch;
      });

      setFilteredSports(filtered);
    } else {
      setFilteredSports(sportOptions); // Show all options if input is empty
    }
  };

  return (
    <Select
      value={sportOptions.find((option) => option.value === value)}
      onChange={onChange}
      onInputChange={loadSportOptions} // Triggers whenever the input value changes
      options={filteredSports} // Use filtered sports as options
      placeholder={placeholder}
      isDisabled={isDisabled}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        control: (base) => ({
          ...base,
          padding: '4px',
          border: 'none',
          boxShadow: 'none',
          minHeight: 'inherit',
          ...customStyles.control, // Add custom control styles if provided
        }),
        input: (base) => ({
          ...base,
          padding: '4px',
          ...customStyles.input, // Add custom input styles if provided
        }),
      }}
    />
  );
}

export default SportSelect;
