import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Button, CircularProgress, Box, Typography, Grid, IconButton } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../contexts/UserContext';

const MyStripeAccount = ({ stripeAccountId }) => {
  const { t } = useTranslation();

  const [accountInfo, setAccountInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const { userInfo, refreshUserInfo } = useContext(UserContext);

  useEffect(() => {
    const fetchAccountInfo = async () => {
      if (!stripeAccountId) return;
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/account-info/${stripeAccountId}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.sessionToken}`
            }
          }
        );
        setAccountInfo(response.data);
      } catch (error) {
        console.error('Error fetching account info:', error);
      }
    };

    fetchAccountInfo();
  }, [stripeAccountId]);

  const handleOnboarding = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/create-account-link`, {
        accountId: stripeAccountId,
      },
      {
        headers: {
          Authorization: `Bearer ${userInfo.sessionToken}`
        },
      }
    );
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error creating account link:', error);
    } finally {
      setLoading(false);
    }
  };

  const getStatusIcon = (status) => {
    return status ? <CheckCircleOutlineIcon color="success" /> : <ErrorOutlineIcon color="error" />;
  };

  return (
    <Box sx={{ p: 3 }}>

      {/* Onboarding Button */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOnboarding}
          disabled={loading || !stripeAccountId}
          startIcon={loading ? <CircularProgress size={20} /> : <CheckCircleOutlineIcon />}
          sx={{ width: '100%', maxWidth: '300px' }}
        >
          {loading ? t('loading') : t('manage_account_with_stripe')}
        </Button>
      </Box>




      {accountInfo ? (
        <Box sx={{ my: 2 }}>
          {/* Account Status Line */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <VerifiedUserIcon sx={{ mr: 1 }} />
            <Typography variant="body1" sx={{ flexGrow: 1 }}>
              {t('account_status')}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {accountInfo.account.details_submitted ? t('complete') : t('incomplete')}
            </Typography>
            <IconButton>{getStatusIcon(accountInfo.account.details_submitted)}</IconButton>
          </Box>

          {/* Bank Account Line */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AccountBalanceIcon sx={{ mr: 1 }} />
            <Typography variant="body1" sx={{ flexGrow: 1 }}>
              {t('bank_account')}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {accountInfo.account.external_accounts.data.length > 0 ? t('linked') : t('not_linked')}
            </Typography>
            <IconButton>{getStatusIcon(accountInfo.account.external_accounts.data.length > 0)}</IconButton>
          </Box>

          {/* Transfers Capability Line */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AttachMoneyIcon sx={{ mr: 1 }} />
            <Typography variant="body1" sx={{ flexGrow: 1 }}>
              {t('transfers_capability')}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {accountInfo.transfersCapability === 'active' ? t('active') : t('not_active')}
            </Typography>
            <IconButton>{getStatusIcon(accountInfo.transfersCapability === 'active')}</IconButton>
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <CircularProgress />
          <Typography variant="body1" sx={{ ml: 2 }}>
            {t('loading_account_info')}
          </Typography>
        </Box>
      )}


      {/* Info Box for Stripe Steps */}
      {!accountInfo || !accountInfo.account.details_submitted || accountInfo.account.external_accounts.data.length === 0 || accountInfo.transfersCapability !== 'active' ? (
        <Box sx={{ mt: 4, p: 2, border: '1px solid #e0e0e0', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
          <Typography variant="h6" component="h3" gutterBottom>
            <InfoIcon sx={{ mr: 1 }} /> {t('steps_to_complete_stripe_setup')}
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('step_1_stripe')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('step_2_stripe')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('step_3_stripe')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('step_4_stripe')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('step_5_stripe')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </Box>
  );
};

export default MyStripeAccount;
