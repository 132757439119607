import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Link,
  Box,
  Alert,
  AlertTitle
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import icon for success message
import axios from 'axios';
import { useNotification } from '../../contexts/NotificationContext';
import { useTranslation } from 'react-i18next';

function Register() {
  const { t } = useTranslation();

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [newsSubscription, setNewsSubscription] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false); // New state to track registration success

  const { showNotification } = useNotification();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/register`, {
        username,
        email,
        password,
        confirmPassword,
        phone,
        newsSubscription,
      });

      // Show success notification from the frontend
      showNotification(response.data.message, 'success');

      // Set registration success to true to display success message
      setRegistrationSuccess(true);
    } catch (error) {
      // Handle error from the backend and show it in a notification
      showNotification(error.response?.data?.message || t('registration_failed'), 'error');
    }
  };

  // If registration is successful, show success message
  if (registrationSuccess) {
    return (
      <Container maxWidth="sm" style={{ textAlign: 'center', marginBottom: "30px" }}>
        <CheckCircleIcon style={{ fontSize: '64px', color: 'green' }} />
        <Typography variant="h5" gutterBottom >
          {t('registration_successful')}
        </Typography>
        <Alert severity="info" style={{ marginTop: '16px' }}>
          <AlertTitle>{t('check_email_activation')}</AlertTitle>
          {t('activation_email_sent')} {email}
          <br />
          {t('check_spam_folder')}
        </Alert>
      </Container>
    );
  }

  // If registration is not successful, show the form
  return (
    <Container maxWidth="sm" style={{ marginBottom: "30px" }}>
      <Typography variant="h4" gutterBottom align="center">
        {t('registration')}

      </Typography>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          label={t('username1')}
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label={t('email1')}
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label={t('password')}
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          label={t('confirm_password')}
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <TextField
          label={t('phone_number_optional')}
          variant="outlined"
          fullWidth
          margin="normal"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <FormControlLabel
          control={<Checkbox checked={newsSubscription} onChange={(e) => setNewsSubscription(e.target.checked)} />}
          label={t('news_subscription')}
        />
        <Button variant="contained" color="primary" fullWidth type="submit" style={{ marginTop: '16px' }}>
          {t('sign_up')}
        </Button>

      </form>
    </Container>
  );
}

export default Register;

