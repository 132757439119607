import React, { useState, useContext } from 'react';
import { Container, TextField, Button, Checkbox, FormControlLabel, Typography, Link, Box } from '@mui/material';
import axios from 'axios';
import { useNotification } from '../../contexts/NotificationContext'; // Import the notification context
import { UserContext } from '../../contexts/UserContext'; // Import UserContext
import PasswordResetRequest from './PasswordResetRequest'; // Import the modal component
import { useTranslation } from 'react-i18next';

function Login({ onLoginSuccess }) {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);

  // Destructure showNotification from the notification context
  const { showNotification } = useNotification(); // Fix: destructure correctly
  const { handleLoginSuccess } = useContext(UserContext); // Use handleLoginSuccess from UserContext

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/login`, {
        email,
        password,
      });

      // Show success notification
      showNotification(response.data.message, 'success');

      // Store the session token in localStorage
      localStorage.setItem('sessionToken', response.data.sessionToken);

      // Trigger handleLoginSuccess to update the user context
      handleLoginSuccess();

      // Close the modal
      onLoginSuccess();

    } catch (error) {
      showNotification(error.response?.data?.message || t('login_failed'), 'error');
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginBottom: "30px" }}>
      <Typography variant="h4" gutterBottom align="center">
        {t('login')}
      </Typography>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          label={t('email')}
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label={t('password')}
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <FormControlLabel
          control={<Checkbox checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />}
          label={t('remember_me')}
        />


        <Button type="submit" variant="contained" color="primary" fullWidth>
          {t('login')}
        </Button>

      </form>
      <Typography
        onClick={() => setShowResetModal(true)}
        sx={{
          color: 'primary.main', // MUI theme color for buttons (blue)
          fontWeight: 'bold',
          cursor: 'pointer',
          display: 'inline-block',
          marginTop: '16px',
          '&:hover': {
            textDecoration: 'underline',
          }
        }}
      >
        {t('forgot_password')}
      </Typography>

      <PasswordResetRequest open={showResetModal} onClose={() => setShowResetModal(false)} />

    </Container>
  );
}

export default Login;

