import React, { useEffect, useState, useContext } from 'react';
import {
  Typography, Box, Button, List, ListItem, ListItemText, TextField, Avatar, IconButton, Tooltip, Grid, Divider
} from '@mui/material';
import { CheckCircle, Cancel, LocationOn, Event, AccessTime } from '@mui/icons-material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../contexts/UserContext';

const ManageActivities = () => {
  const { t } = useTranslation();
  const { userInfo, refreshUserInfo } = useContext(UserContext);

  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [rejectReason, setRejectReason] = useState({});


  // Fetch all not reviewed activities
  const fetchNotReviewedActivities = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/activities/not-reviewed`, {
        headers: {
          Authorization: `Bearer ${userInfo.sessionToken}`
        },
      });
      setActivities(response.data);
      setLoading(false);
    } catch (err) {
      setError(t('failed_to_load_activities'));
      setLoading(false);
    }
  };

  // Approve activity function
  const approveActivity = async (activityId) => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/admin/activities/${activityId}/approve`, {}, {
        headers: {
          Authorization: `Bearer ${userInfo.sessionToken}`
        },
      });
      // Update UI after approval
      setActivities(activities.filter(activity => activity._id !== activityId));
    } catch (err) {
      setError(t('failed_to_approve_activity'));
    }
  };

  // Reject activity function
  const rejectActivity = async (activityId) => {
    if (!rejectReason[activityId]) {
      setError(t('please_provide_rejection_reason'));
      return;
    }

    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/admin/activities/${activityId}/reject`,
        { reason: rejectReason[activityId] },
        {
          headers: {
            Authorization: `Bearer ${userInfo.sessionToken}`
          },
        });
      // Update UI after rejection
      setActivities(activities.filter(activity => activity._id !== activityId));
    } catch (err) {
      setError(t('failed_to_reject_activity'));
    }
  };

  // Handle reason input change
  const handleReasonChange = (activityId, value) => {
    setRejectReason({ ...rejectReason, [activityId]: value });
  };

  // Load the activities on component mount
  useEffect(() => {
    fetchNotReviewedActivities();
  }, []);

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
      {t('manage_activities')}
      </Typography>
   

      {loading && <Typography>{t('loading')}</Typography>}
      {error && <Typography color="error">{error}</Typography>}

      {!loading && activities.length === 0 && (
        <Typography>{t('no_pending_activities')}</Typography>
      )}

      <List>
        {activities.map(activity => (
          <ListItem key={activity._id} sx={{ mb: 3, border: "2px dashed #c1c1c173", borderRadius: "15px" }}>
            <Grid container spacing={2}>
              {/* Left Side (Activity Data) */}
              <Grid item xs={12} md={5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <ListItemText
                  primary={
                    <>
                      <Typography variant="subtitle1" component="div">{activity.title}</Typography>
                      <Typography variant="caption" component="div" color="textSecondary">ID: {activity._id}</Typography>
                      <Typography variant="caption" component="div" color="textSecondary">{t('organizer')}: {activity.organizer.username}</Typography>
                      <Typography variant="caption" component="div" color="textSecondary">{t('created_at')}: {new Date(activity.createdAt).toLocaleString()}</Typography>
                    </>
                  }
                  secondary={
                    <>
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                        <Event fontSize="small" sx={{ mr: 0.5 }} />
                        <Typography variant="body2">{t('date')}: {activity.date}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                        <AccessTime fontSize="small" sx={{ mr: 0.5 }} />
                        <Typography variant="body2">{t('time')}: {activity.time}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                        <LocationOn fontSize="small" sx={{ mr: 0.5 }} />
                        <Typography variant="body2">{t('location')}: {activity.locationName}, {activity.city}</Typography>
                      </Box>
                      <Typography variant="body2" mt={1}>{t('address')}: {activity.location}</Typography>
                      <Typography variant="body2" mt={1}>{t('description_createActivity')}: {activity.description}</Typography>
                    </>
                  }
                />
              </Grid>

              {/* Right Side (Image and Actions) */}
              <Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                {/* Image */}
                <Box sx={{
                  width: '100%',
                  height: 0,
                  paddingBottom: '60%', // Aspect ratio 60%
                  position: 'relative',
                  overflow: 'hidden',
                  backgroundColor: '#f0f0f0',
                  mb: 1,
                }}>
                  <Avatar
                    variant="square"
                    src={activity.imageUrl}
                    alt="Activity Image"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>

                {/* Rejection Input and Buttons */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    label={t('rejection_reason')}
                    variant="outlined"
                    size="small"
                    value={rejectReason[activity._id] || ''}
                    onChange={(e) => handleReasonChange(activity._id, e.target.value)}
                    sx={{ flexGrow: 1, mr: 2 }}
                  />
                  <Tooltip title={t('approve')} placement="top">
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => approveActivity(activity._id)}
                      sx={{ mr: 1 }}
                    >
                      <CheckCircle />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('reject')} placement="top">
                    <IconButton
                      color="error"
                      size="small"
                      onClick={() => rejectActivity(activity._id)}
                      disabled={!rejectReason[activity._id]}
                    >
                      <Cancel />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 2 }} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ManageActivities;
