import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';

// Create the UserContext
export const UserContext = createContext();

// UserProvider to wrap around the app
export const UserProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track user login status
  const [userInfo, setUserInfo] = useState(null); // Store user information
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track errors
  const [notifications, setNotifications] = useState([]); // Store user notifications

  // Check session on page load to see if user is logged in
  useEffect(() => {
    const sessionToken = localStorage.getItem('sessionToken');

    if (sessionToken) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/session`, {
          headers: { Authorization: sessionToken },
        })
        .then((response) => {
          setIsLoggedIn(true);
          setUserInfo({ ...response.data, sessionToken });  // Include sessionToken in userInfo
        })
        .catch((err) => {
          console.error('Session check failed:', err);
          localStorage.removeItem('sessionToken'); // Remove invalid session token
        })
        .finally(() => {
          setLoading(false); // Set loading to false after session check is done
        });
    } else {
      setLoading(false); // No session token, set loading to false
    }
  }, []);

  // Function to handle login success
  const handleLoginSuccess = useCallback(() => {
    const sessionToken = localStorage.getItem('sessionToken');

    if (sessionToken) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/session`, {
          headers: { Authorization: sessionToken },
        })
        .then((response) => {
          setIsLoggedIn(true);
          setUserInfo({ ...response.data, sessionToken });  // Include sessionToken in userInfo
        })
        .catch((err) => {
          console.error('Error after login:', err);
          localStorage.removeItem('sessionToken');
        });
    }
  }, []);


  // Function to handle logout
  const handleLogout = useCallback(() => {
    localStorage.removeItem('sessionToken');
    setIsLoggedIn(false);
    setUserInfo(null);
  }, []);

  // Function to refresh user information (for profile updates, etc.)
  const refreshUserInfo = useCallback(() => {
    const sessionToken = localStorage.getItem('sessionToken');

    if (sessionToken) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/session`, {
          headers: { Authorization: sessionToken },
        })
        .then((response) => {
          setUserInfo(response.data);
        })
        .catch((err) => {
          console.error('Error refreshing user info:', err);
        });
    }
  }, []);

  // Fetch notifications for the logged-in user
  useEffect(() => {

    if (userInfo && userInfo._id) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/notifications/${userInfo._id}`, {
          headers: {
            Authorization: `Bearer ${userInfo.sessionToken}`,
          }
        })
        .then((response) => {
          setNotifications(response.data.notifications);
        })
        .catch((err) => {
          console.error('Error fetching notifications:', err);
        });
    }
  }, [userInfo]);

  // Mark a notification as read
  const markNotificationAsRead = useCallback((notificationId) => {
    if (!userInfo) {
      console.error("User info is not available");
      return;
    }
    axios
      .put(`${process.env.REACT_APP_BACKEND_URL}/api/notifications/${notificationId}/read`, {}, {
        headers: {
          Authorization: `Bearer ${userInfo.sessionToken}`,
        }
      })
      .then(() => {
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification._id === notificationId
              ? { ...notification, read: true }
              : notification
          )
        );
      })
      .catch((err) => {
        console.error('Error marking notification as read:', err);
      });
  }, [userInfo]);

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        userInfo,
        loading,
        error,
        handleLoginSuccess,
        handleLogout,
        refreshUserInfo,
        notifications,
        markNotificationAsRead,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

