import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const AdminRoute = ({ children }) => {
  const { isLoggedIn, userInfo, loading } = useContext(UserContext);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isLoggedIn || !userInfo?.isAdmin) {
    // Redirect to home if user is not logged in or not an admin
    return <Navigate to="/" />;
  }

  return children;
};

export default AdminRoute;
