import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';

const MyPayments = ({ userId, sessionToken }) => {
    const { t } = useTranslation();

    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch the payment data from backend
    useEffect(() => {
        const fetchPayments = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/${userId}/payments`, {
                    headers: {
                        Authorization: `Bearer ${sessionToken}`,
                    },
                });

                setPayments(response.data.payments); // Assuming the response contains { payments: [...] }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching payments:', error);
                setError(t('error_fetching_payments'));
                setLoading(false);
            }
        };

        fetchPayments();
    }, [userId, sessionToken]);

    // Conditional rendering based on loading or error states
    if (loading) {
        return (
            <Box sx={{ p: 3 }}>
                <CircularProgress />
                <Typography variant="body1">{t('loading_payments')}</Typography>
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 3 }}>
                <ErrorOutlineIcon color="error" />
                <Typography variant="body1" color="error">{error}</Typography>
            </Box>
        );
    }

    // Handle the case when no payments exist
    if (payments.length === 0) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography variant="h5" gutterBottom>{t('no_payments_found')}</Typography>
                <Typography variant="body1">
                {t('no_payments_message')}
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('activity')}</TableCell>
                            <TableCell>{t('amount_paid')}</TableCell>
                            <TableCell>{t('date')}</TableCell>
                            <TableCell>{t('status')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payments.map((payment, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <a href={`https://buildmateam.com/activity/${payment.activityId}`} target="_blank" rel="noopener noreferrer" style={{ fontWeight: "bolder", fontDecoration: "none", color: "grey" }}>
                                        {payment.activityName}
                                    </a>
                                </TableCell>
                                <TableCell>€{(payment.amountPaid / 100).toFixed(2)}</TableCell> {/* Convert cents to euros */}
                                <TableCell>{new Date(payment.subscribedOn).toLocaleDateString()}</TableCell>
                                <TableCell>
                                    {payment.paymentStatus === 'paid' ? (
                                        <IconButton aria-label="Paid">
                                            <CheckCircleOutlineIcon color="success" />
                                        </IconButton>
                                    ) : (
                                        <IconButton aria-label="Not Paid">
                                            <ErrorOutlineIcon color="error" />
                                        </IconButton>
                                    )}
                                    {payment.paymentStatus}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default MyPayments;
