import React, { useContext, useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, IconButton, Avatar } from '@mui/material';
import { UserContext } from '../../contexts/UserContext';
import { useNotification } from '../../contexts/NotificationContext'; // Import notification context
import axios from 'axios';
import CitySelect from '../../components/CitySelect'; // Import the CitySelect component
/* import MyStripeAccount from './MyStripeAccount';
 */
import { useTranslation } from 'react-i18next';

function Account() {
  const { t } = useTranslation();

  const { userInfo, refreshUserInfo } = useContext(UserContext);
  const { showNotification } = useNotification(); // Destructure showNotification from the notification context
  const [editingField, setEditingField] = useState(null); // Track which field is being edited
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    phone: '',
    city: '',
    description: '',
    avatar: null,
  });
  const [avatarPreview, setAvatarPreview] = useState(''); // To display the avatar
  const [isEmailVerified, setIsEmailVerified] = useState(true); // By default, assume the email is verified

  // Load user data on component mount
  useEffect(() => {
    if (userInfo) {
      setFormData({
        username: userInfo.username || '',
        email: userInfo.email || '',
        phone: userInfo.phone || '',
        city: userInfo.city || '', // Set current city if available
        description: userInfo.description || '',
        avatar: null, // Set as null initially
      });

      // If user already has an avatar, set it as the avatar preview
      if (userInfo.avatar) {
        setAvatarPreview(`${process.env.REACT_APP_BACKEND_URL}${userInfo.avatar}`); // Use the avatar URL from the backend
      }
    }
  }, [userInfo]);

  const resendVerificationEmail = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/resend-verify-email`, {
        email: userInfo.email,
      }, {
        headers: {
          Authorization: `Bearer ${userInfo.sessionToken}`,
        },
      });

      showNotification(response.data.message, 'success');
    } catch (err) {
      console.error('Error resending verification email:', err);
      showNotification(err.response?.data?.message || t('verification_email_resend_error'), 'error');
    }
  };

  useEffect(() => {
    const checkEmailVerificationStatus = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/verified-email`, {
          headers: {
            Authorization: `Bearer ${userInfo.sessionToken}`,
          },
        });

        setIsEmailVerified(response.data.emailVerified);
      } catch (err) {
        console.error('Error checking email verification status:', err);
        showNotification(t('email_verification_check_error'), 'error');
      }
    };

    checkEmailVerificationStatus();
  }, [userInfo, showNotification, t]);

  // Handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission for updating user info
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the data with FormData for file upload
    const formDataToSend = new FormData();
    formDataToSend.append('username', formData.username);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('city', formData.city);
    formDataToSend.append('description', formData.description);

    if (formData.avatar) {
      formDataToSend.append('avatar', formData.avatar);  // Add the avatar file if a new one is selected
    }

    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/user/${userInfo._id}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.sessionToken}`
        },
      });

      showNotification(response.data.message, 'success'); // Show success notification
      refreshUserInfo();  // Refresh the user info after successful update
    } catch (err) {
      showNotification(err.response?.data?.message || t('update_error'), 'error'); // Show error notification
    }
  };

  // Handle avatar file change
  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prev) => ({ ...prev, avatar: file }));  // Store the file for upload
      setAvatarPreview(URL.createObjectURL(file)); // Show the local preview of the uploaded file
    }
  };

  // Allow editing of a specific field
  const handleEdit = (field) => {
    setEditingField(field);
  };

  // Handle city change
  const handleCityChange = (selectedOption) => {
    setFormData((prev) => ({ ...prev, city: selectedOption.value }));
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        {t('my_account')}
      </Typography>
      <form onSubmit={handleSubmit}>
        {/* Avatar Display */}
        <Avatar
          src={avatarPreview || '/placeholder-avatar.png'}
          alt="Avatar"
          sx={{ width: 100, height: 100, marginBottom: 2 }}
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleAvatarChange}
          disabled={editingField !== 'avatar'}
        />
        <IconButton onClick={() => handleEdit('avatar')}>
          ✏️
        </IconButton>

        {/* Username */}
        <TextField
          label={t('username')}
          name="username"
          value={formData.username}
          onChange={handleChange}
          fullWidth
          margin="normal"
          disabled={editingField !== 'username'}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => handleEdit('username')}>
                ✏️
              </IconButton>
            ),
          }}
        />

        {/* Email */}
        <TextField
          label={t('email')}
          name="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
          disabled={editingField !== 'email'}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => handleEdit('email')}>
                ✏️
              </IconButton>
            ),
          }}
        />

        {!isEmailVerified && (
          <>
            <Typography>{t('resend_verification_txt')}</Typography>
            <Button
              variant="outlined"
              color="secondary"
              onClick={resendVerificationEmail}
              style={{ marginTop: '16px' }}
            >
              {t('resend_verification_email')}
            </Button>
          </>
        )}

        {/* Phone */}
        <TextField
          label={t('phone_number')}
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          fullWidth
          margin="normal"
          disabled={editingField !== 'phone'}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => handleEdit('phone')}>
                ✏️
              </IconButton>
            ),
          }}
        />

        {/* City Selection */}
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', zIndex: 10 }}>
          <div style={{ flexGrow: 1 }}>
            <CitySelect
              value={formData.city}
              onChange={handleCityChange}
              isDisabled={editingField !== 'city'}
            />
          </div>
          <IconButton onClick={() => handleEdit('city')} style={{ marginLeft: '8px' }}>
            ✏️
          </IconButton>
        </div>

        {/* Description */}
        <TextField
          label={t('description_text')}
          name="description"
          value={formData.description}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
          disabled={editingField !== 'description'}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => handleEdit('description')}>
                ✏️
              </IconButton>
            ),
          }}
        />

        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px' }}>
          {t('update')}
        </Button>
      </form>

      {/*  {userInfo && (
        <MyStripeAccount stripeAccountId={userInfo?.stripeAccountId} />
      )} */}

    </Container>
  );
}

export default Account;

