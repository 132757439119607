import React, { useState } from 'react';
import { Box, Container, Typography, Link, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import ReportButton from './ReportButton';
import { useTranslation } from 'react-i18next';
import TermsModalTrigger from './TermsModal';


function Footer() {
  const { t } = useTranslation();

  // State to handle modals
  const [openAbout, setOpenAbout] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);

  const handleOpenAbout = () => setOpenAbout(true);
  const handleCloseAbout = () => setOpenAbout(false);

  const handleOpenTerms = () => setOpenTerms(true);
  const handleCloseTerms = () => setOpenTerms(false);

  return (
    <Box sx={{ backgroundColor: '#f8f8f8', padding: '16px 0', marginTop: '30px' }}>
      <Container maxWidth="lg">
        <Typography variant="body1" align="center" gutterBottom>
          &copy; {new Date().getFullYear()} {t('footer_copyright')}
        </Typography>
        <Typography variant="body2" align="center" color="textSecondary">
          {/* Open "About" modal on click */}
          <Link component="button" onClick={handleOpenAbout} color="inherit">
            {t('footer_about')}
          </Link>
          {' | '}
          {/* Open "Terms" modal on click */}
          <TermsModalTrigger buttonText={t('footer_terms')} />

          {' | '}
          <span style={{ textDecoration: "underline", cursor: "pointer" }}>
            <ReportButton
              type="suggestion"
              buttonText={t('suggestion')}
              buttonStyle="grey"
              onSuccess={() => console.log('Suggestion idea submitted!')}
              textOnly={true}
            />
          </span>
        </Typography>
      </Container>

      {/* About Modal */}
      <Dialog open={openAbout} onClose={handleCloseAbout}>
        <DialogTitle>{t('footer_about')}</DialogTitle>
        <DialogContent>
          <Typography>
            {t('footer_about_content')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAbout} color="primary">
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
}

export default Footer;
