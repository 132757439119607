// components/ManageUsers.jsx
import React, { useState, useContext } from 'react';
import { Typography, Box, TextField, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Select, MenuItem, Dialog, DialogTitle, DialogContent, Avatar } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../contexts/UserContext';

const ManageUsers = () => {
  const { t } = useTranslation();
  const { userInfo, refreshUserInfo } = useContext(UserContext);

  const [searchParams, setSearchParams] = useState({
    username: '',
    email: '',
    phone: '',
    city: '',
    newsSubscription: 'all',
    isAdmin: 'all',
  });
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null); // For displaying detailed user info in modal

  // Handle input changes for search filters
  const handleChange = (e) => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
  };

  // Handle form submission to search users
  const handleSearch = async () => {
    setLoading(true);
    setError(null);

    try {
      const queryParams = {
        ...searchParams,
        newsSubscription: searchParams.newsSubscription !== 'all' ? searchParams.newsSubscription : undefined,
        isAdmin: searchParams.isAdmin !== 'all' ? searchParams.isAdmin : undefined,
      };

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/users/search`, {
        headers: {
          Authorization: `Bearer ${userInfo.sessionToken}`,
        },
        params: queryParams,
      });

      setUsers(response.data);
    } catch (err) {
      setError('Failed to fetch users.');
    } finally {
      setLoading(false);
    }
  };

  // Open Modal with user details
  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  // Close Modal
  const handleClose = () => {
    setSelectedUser(null);
  };

  return (
    <Box sx={{ width: '100%', padding: '16px' }}>
      <Typography variant="h4" gutterBottom>
      {t('manage_users')}
      </Typography>

      {/* Search Filters */}
      <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
        <Grid item xs={6} md={4}>
          <TextField
            label={t('username')}
            name="username"
            value={searchParams.username}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            label={t('email')}
            name="email"
            value={searchParams.email}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            label={t('phone')}
            name="phone"
            value={searchParams.phone}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            label={t('city')}
            name="city"
            value={searchParams.city}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <Select
            label={t('news_subscription')}
            name="newsSubscription"
            value={searchParams.newsSubscription}
            onChange={handleChange}
            fullWidth
          >
            <MenuItem value="all">{t('all')}</MenuItem>
            <MenuItem value="true">{t('subscribed')}</MenuItem>
            <MenuItem value="false">{t('not_subscribed')}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6} md={4}>
          <Select
            label="Is Admin"
            name="isAdmin"
            value={searchParams.isAdmin}
            onChange={handleChange}
            fullWidth
          >
            <MenuItem value="all">{t('all')}</MenuItem>
            <MenuItem value="true">{t('admin')}</MenuItem>
            <MenuItem value="false">{t('not_admin')}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button variant="contained" color="primary" onClick={handleSearch} fullWidth>
          {t('search_users')}
          </Button>
        </Grid>
      </Grid>

      {/* Results Table */}
      {loading && <Typography>{t('loading')}</Typography>}
      {error && <Typography color="error">{error}</Typography>}

      {users.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('username')}</TableCell>
                <TableCell>{t('email')}</TableCell>
                <TableCell>{t('phone')}</TableCell>
                <TableCell>{t('city')}</TableCell>
                <TableCell>{t('news_subscription')}</TableCell>
                <TableCell>{t('is_admin')}</TableCell>
                <TableCell>{t('details')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user._id}>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.phone || 'N/A'}</TableCell>
                  <TableCell>{user.city || 'N/A'}</TableCell>
                  <TableCell>{user.newsSubscription ? t('subscribed') : t('not_subscribed')}</TableCell>
                  <TableCell>{user.isAdmin ? t('yes') : t('no')}</TableCell>
                  <TableCell>
                    <Button variant="outlined" onClick={() => handleUserClick(user)}>
                    {t('view_details')}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {!loading && users.length === 0 && <Typography>{t('no_users_found')}</Typography>}

      {/* Modal for displaying user details */}
      {selectedUser && (
        <Dialog open={!!selectedUser} onClose={handleClose} fullWidth>
          <DialogTitle>{t('user_details')}</DialogTitle>
          <DialogContent>
            <Avatar src={process.env.REACT_APP_BACKEND_URL + selectedUser.avatar} sx={{ width: 100, height: 100, marginBottom: '16px' }} />
            <Typography variant="body1"><strong>ID:</strong> {selectedUser._id}</Typography>
            <Typography variant="body1"><strong>{t('username')}:</strong> {selectedUser.username}</Typography>
            <Typography variant="body1"><strong>{t('email')}:</strong> {selectedUser.email}</Typography>
            <Typography variant="body1"><strong>{t('phone')}:</strong> {selectedUser.phone || 'N/A'}</Typography>
            <Typography variant="body1"><strong>{t('city')}:</strong> {selectedUser.city || 'N/A'}</Typography>
            <Typography variant="body1"><strong>{t('description_text')}:</strong> {selectedUser.description || 'N/A'}</Typography>
            <Typography variant="body1"><strong>{t('news_subscription')}:</strong> {selectedUser.newsSubscription ? t('subscribed'): t('not_subscribed')}</Typography>
            <Typography variant="body1"><strong>{t('is_admin')}:</strong> {selectedUser.isAdmin ? t('yes') : t('no')}</Typography>
            <Typography variant="body1"><strong>{t('stripe_account_id')}:</strong> {selectedUser.stripeAccountId || 'N/A'}</Typography>
            <Typography variant="body1"><strong>{t('created_at')}:</strong> {new Date(selectedUser.createdAt).toLocaleString()}</Typography>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default ManageUsers;
