import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, CircularProgress, Box, Grid, Avatar, Pagination } from '@mui/material';
import MiniActivityCard from '../../components/MiniActivityCard'; // Import the MiniActivityCard
import ReportButton from '../../components/ReportButton';
import { useTranslation } from 'react-i18next';

const UserProfile = () => {
  const { t } = useTranslation();

  const { userId } = useParams(); // Get userId from the URL parameters
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1); // Pagination state
  const [activitiesPerPage] = useState(5); // Number of activities per page

  useEffect(() => {
    // Fetch user profile and activities data
    async function fetchUserProfile() {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/profile/${userId}`);
        const data = await response.json();
        setUserData(data);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchUserProfile();
  }, [userId]);

  // Handle pagination change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Logic for displaying activities per page
  const indexOfLastActivity = page * activitiesPerPage;
  const indexOfFirstActivity = indexOfLastActivity - activitiesPerPage;
  const currentActivities = userData?.activities?.slice(indexOfFirstActivity, indexOfLastActivity);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!userData || !userData.user) {
    return <Typography>{t('user_not_found')}</Typography>;
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <ReportButton
        type="user"
        targetId={userData.user._id}
        buttonText={t('report')}
        buttonStyle="grey"
        onSuccess={() => console.log('Report submitted for user!')}
      />
      {/* User Information */}
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems="center"
        sx={{ mb: 4, textAlign: { xs: 'center', md: 'left' } }}
      >
        <Avatar
          alt={userData.user.username}
          src={`${process.env.REACT_APP_BACKEND_URL}${userData.user.avatar}`}
          sx={{ width: 120, height: 120, mb: { xs: 2, md: 0 }, mr: { md: 4 } }}
        />
        <Box>
          <Typography variant="h4" fontWeight="bold">{userData.user.username}</Typography>
          <Typography variant="body1" color="textSecondary">{t('city')}: {userData.user.city || "N/A"}</Typography>
          <Typography variant="body1" color="textSecondary" mt={1}>
            {userData.user.description || "No description provided."}
          </Typography>
        </Box>
      </Box>

      {/* Activities List */}
      <Typography variant="h5" gutterBottom fontWeight="bold" sx={{ mb: 3 }}>
        {t('activities_created_by')} {userData.user.username}:
      </Typography>

      <Grid container spacing={2}>
        {currentActivities && currentActivities.length > 0 ? (
          currentActivities.map((activity) => (
            <Grid item xs={12} sm={6} md={4} key={activity._id}>
              {/* Use MiniActivityCard here */}
              <MiniActivityCard activity={activity} />
            </Grid>
          ))
        ) : (
          <Typography variant="body1" color="textSecondary" sx={{ mx: 'auto', mt: 4 }}>
            {t('no_activities_found')}
          </Typography>
        )}
      </Grid>

      {/* Pagination */}
      <Box display="flex" justifyContent="center" mt={4}>
        <Pagination
          count={Math.ceil(userData.activities.length / activitiesPerPage)}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Container>
  );
};

export default UserProfile;
