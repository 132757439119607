import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { CircularProgress, Box } from '@mui/material';

const PrivateRoute = ({ children }) => {
  const { isLoggedIn, loading } = useContext(UserContext);

  if (loading) {
    // Show a centered CircularProgress loader while loading
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Full height to center the loader vertically and horizontally
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return isLoggedIn ? children : <Navigate to="/" />; // Redirect to login if not authenticated
};

export default PrivateRoute;
