import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
} from '@mui/material';
import { UserContext } from '../contexts/UserContext';
import ReportIcon from '@mui/icons-material/Report'; // Importing a sample icon
import shadows from '@mui/material/styles/shadows';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Rating } from '@mui/material';
import { useNotification } from '../contexts/NotificationContext'; // Import the notification context
import { useTranslation } from 'react-i18next';

const ReportButton = ({
    type,
    targetId = null,
    preFilledMessage = '',
    buttonText,
    onSuccess,
    buttonStyle = 'white',
    textOnly = false
}) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(preFilledMessage);
    const [rating, setRating] = useState(null); // or useState(0) if you want to initialize it with zero stars
    const [confirmationStep, setConfirmationStep] = useState(false); // State for confirmation step
    const { userInfo } = useContext(UserContext);

    const { showNotification } = useNotification(); // Fix: destructure correctly

    // Modal handling functions
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setConfirmationStep(false); // Reset confirmation step when closing
    };

    // Custom title messages based on report type
    const getCustomMessageTitle = () => {
        switch (type) {
            case 'activity':
                return t('report_activity');
            case 'user':
                return t('report_user');
            case 'suggestion':
                return t('submit_suggestion');
            case 'issue':
                return t('report_issue');
            case 'assistance':
                return t('request_assistance');
            default:
                return t('submit_report');
        }
    };

    // Custom paragraph text based on report type
    const getCustomParagraphText = () => {
        switch (type) {
            case 'activity':
                return t('activity_report_details');
            case 'user':
                return t('user_report_details');
            case 'suggestion':
                return t('suggestion_feedback');
            case 'issue':
                return t('issue_report_details');
            case 'assistance':
                return t('assistance_request_details');
            default:
                return t('general_report_details');
        }
    };

    // Form submission handling
    const handleSubmit = async () => {
        if (!userInfo) {
            showNotification(t('user_notauth'), 'error');
            return;
        }

        try {
            const reportData = {
                userId: userInfo._id,
                type,
                targetId,
                message,
                rating: rating && type === 'suggestion' ? rating : null,
            };

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/submit-report`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.sessionToken}`,
                },
                body: JSON.stringify(reportData),
            });

            if (!response.ok) {
                throw new Error(t('submit_report_error'));
            }

            if (onSuccess) onSuccess();
            showNotification(t('submit_report_success'), 'success');
            handleClose();
        } catch (error) {
            console.error('Error submitting report:', error);
            showNotification(t('submit_report_error'), 'error');
        }
    };

    // Style for the MUI modal box
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <>
            {textOnly ? (
                <span onClick={handleOpen}>{buttonText}</span>
            ) :
                (
                    <Button
                        variant={'outlined'}
                        color={buttonStyle}
                        onClick={handleOpen}
                        startIcon={type === 'activity' ? <ReportIcon /> : null} // Adding icon for activity
                        sx={{ fontWeight: "bolder", color: buttonStyle, border: "none" }}
                    >
                        {buttonText}
                    </Button>
                )
            }



            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="report-modal-title"
                aria-describedby="report-modal-description"
            >
                <Box sx={style}>
                    <Typography id="report-modal-title" variant="h6" component="h2">
                        {getCustomMessageTitle()}
                    </Typography>
                    <Typography id="report-modal-description" variant="body1" sx={{ mt: 1 }}>
                        {getCustomParagraphText()}
                    </Typography>
                    {!confirmationStep ? (
                        <Box
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                mt: 2,
                            }}
                        >

                            {type === 'suggestion' && (
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                    <Typography component="legend" sx={{ minWidth: 80 }}>{t('rating')}</Typography> {/* Fixed width for label */}
                                    <Rating
                                        name="star-rating"
                                        value={rating}
                                        onChange={(event, newValue) => setRating(newValue)} // Handle star click and update state
                                        precision={1} // Allow full-star increments
                                        icon={<StarIcon fontSize="inherit" />} // Ensure the size stays consistent
                                        emptyIcon={<StarBorderIcon fontSize="inherit" />} // Same size for empty stars
                                        sx={{
                                            ml: 2, // Add some margin to the left to space it from the label
                                            fontSize: 30, // Set a fixed size for the stars (adjust as needed)
                                            flexShrink: 0, // Prevent shrinking
                                        }}
                                    />
                                </Box>
                            )}
                            
                            <TextField
                                label="Message"
                                multiline
                                rows={4}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                                fullWidth
                            />

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setConfirmationStep(true)}
                                disabled={message.trim().length === 0} // Disable if message is empty or only whitespace
                            >
                                {t('confirm')}
                            </Button>
                        </Box>
                    ) : (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body1">
                                <strong>{t('submit_report_summary')}:</strong>
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                <strong>{t('type')}:</strong> {getCustomMessageTitle()} <br />
                                <strong>{t('message')}:</strong> {message} <br />
                                {type === 'suggestion' && rating && (
                                    <>
                                        <strong>{t('rating')}:</strong> {rating}
                                    </>
                                )}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2 }}
                                onClick={handleSubmit}
                            >
                                {t('submit_report')}
                            </Button>
                        </Box>
                    )}
                </Box>
            </Modal>
        </>
    );
};

export default ReportButton;
