// utils/collectDateTranslations.js
import { useTranslation } from 'react-i18next';

// utils/collectDateTranslations.js
export const getTranslatedCollectDateOptions = (t) => {
    // Return an array of objects with original values and translated labels
    return [
        { value: 'At Event Date and Time', label: t('collect_date.at_event_date') },
        { value: '6 hours before', label: t('collect_date.6_hours_before') },
        { value: '24 hours before', label: t('collect_date.24_hours_before') },
        { value: '2 days before', label: t('collect_date.2_days_before') },
        { value: '5 days before', label: t('collect_date.5_days_before') },
        { value: '7 days before', label: t('collect_date.7_days_before') },
        { value: '2 weeks before', label: t('collect_date.2_weeks_before') },
        { value: '1 month before', label: t('collect_date.1_month_before') },
    ];
};
