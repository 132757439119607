import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CitySelect from '../components/CitySelect'; // Import CitySelect component
import { useTranslation } from 'react-i18next';
import SportSelect from '../components/SportSelect';

const SearchBar = () => {
  const { t } = useTranslation();

  const [activity, setActivity] = useState(''); // Search for the activity keyword
  const [city, setCity] = useState(''); // Search for the city keyword

  const navigate = useNavigate(); // For navigating to the activity list page

  const handleSearch = () => {
    // Navigate to the activity list page with search params
    const queryParams = new URLSearchParams({
      sport: activity,
      location: city,
    }).toString();

    navigate(`/activities?${queryParams}`);
  };

  const handleCityChange = (selectedOption) => {
    setCity(selectedOption ? selectedOption.value : '');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        padding: '4px',
        width: { xs: '100%', md: '350px' }, // Full width on mobile, fixed width on desktop
      }}
    >

      <Box sx={{ flex: 1, minWidth: 0 }}>
        <SportSelect
          value={activity}
          onChange={(option) => setActivity(option?.value || '')}
          placeholder={t('select_sport')}
          customStyles={{
            control: {
              padding: '4px',
              border: 'none',
              boxShadow: 'none',
              minHeight: 'inherit',
            },
            input: {
              padding: '4px',
            },
          }}
        />
      </Box>

      {/* Separator */}
      <Box
        sx={{
          width: '1px',
          height: '24px',
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
        }}
      />

      <Box sx={{ flex: 1, minWidth: 0 }}>
        <CitySelect
          value={city}
          onChange={handleCityChange}
          placeholder={t('city_placeholder')}
          customStyles={{
            control: {
              padding: '4px',
              border: 'none',
              boxShadow: 'none',
              minHeight: 'inherit',
            },
            input: {
              padding: '4px',
            },
          }}
        />
      </Box>

      {/* Search Icon Button */}
      <IconButton sx={{ padding: '4px' }} onClick={handleSearch}>
        <SearchIcon color="action" />
      </IconButton>
    </Box>
  );
};

export default SearchBar;
