import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from '../locales/en/translation.json';
import translationFR from '../locales/fr/translation.json';

// The translations
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

i18n
  .use(LanguageDetector) // Automatically detects user language
  .use(initReactI18next)  // Passes i18n instance to react-i18next
  .init({
    resources,
    fallbackLng: 'fr', // Default language
    lng: 'fr',         // Set French as the default language
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });

export default i18n;
