import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import cityData from '../assets/fr_cities.json'; // Update the path according to your file structure
import { useTranslation } from 'react-i18next';

function CitySelect({ value, onChange, isDisabled = false, placeholder = 'Select a city', customStyles = {} }) {
  const { t } = useTranslation();

  placeholder = t('select_city');
  const [cityOptions, setCityOptions] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);

  // Load city options on component mount
  useEffect(() => {
    const cities = cityData.map((city) => ({
      value: city.Name,
      label: `${city.Name}, ${city['Country Code']}`,
    }));
    setCityOptions(cities);
  }, []);

  // Lazy load city options by filtering based on the starting letters
  const loadCityOptions = (inputValue) => {
    if (inputValue) {
      const filtered = cityOptions.filter((city) =>
        city.label.toLowerCase().startsWith(inputValue.toLowerCase())
      );
      setFilteredCities(filtered);
    } else {
      setFilteredCities([]); // Clear the list when input is empty
    }
  };

  return (
    <Select
      value={cityOptions.find((option) => option.value === value)}
      onChange={onChange}
      onInputChange={loadCityOptions}
      options={filteredCities}
      placeholder={placeholder}
      isDisabled={isDisabled}
      menuPortalTarget={document.body}
      noOptionsMessage={() => t('no_options')} // Customize the message here
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        control: (base) => ({
          ...base,
          padding: '0', // Ensure no extra padding
          margin: '0',  // Ensure no extra margin
          border: 'none',
          boxShadow: 'none',
          minHeight: 'inherit',
          ...customStyles.control, // Add custom control styles if provided
        }),
        input: (base) => ({
          ...base,
          padding: '0', // Ensure no extra padding inside the input
          margin: '0',  // Ensure no extra margin inside the input
          transform: 'translateY(0)', // Aligns the input vertically with the placeholder
          ...customStyles.input, // Add custom input styles if provided
        }),
        dropdownIndicator: (base) => ({
          ...base,
          display: 'none', // Hide the arrow (dropdown indicator)
        }),
        indicatorSeparator: (base) => ({
          ...base,
          display: 'none', // Hide the separator
        }),
      }}
    />


  );
}

export default CitySelect;
