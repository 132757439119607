import React, { useState } from 'react';
import { Box, Button, TextField, Typography, LinearProgress, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PasswordReset = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token'); // Get the reset token from the URL

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('error'); // Can be 'error' or 'success'
  const [loading, setLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false); // Track success

  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length > 8) strength += 25;
    if (/[A-Z]/.test(password)) strength += 25;
    if (/[a-z]/.test(password)) strength += 25;
    if (/[0-9]/.test(password)) strength += 25;
    return strength;
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);
    setPasswordStrength(calculatePasswordStrength(password));
  };

  const handleSubmit = async () => {
    if (!token) {
      setMessage(t('no_token_error'));
      setMessageType('error');
      return;
    }

    if (newPassword !== confirmPassword) {
      setMessage(t('passwords_do_not_match'));
      setMessageType('error');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/resetpass`, {
        token,
        newPassword,
        confirmPassword,
      });
      setMessage(t('password_reset_success'));
      setMessageType('success');
      setIsSuccess(true); // Mark success
    } catch (error) {
      setMessage(t('password_reset_error'));
      setMessageType('error');
    }
    setLoading(false);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      minHeight="100vh"
      sx={{ marginTop: '-10vh' }}  // Adjusts the vertical positioning closer to the top
    >
      <Box
        width="100%"
        maxWidth="400px"
        padding="20px"
        boxShadow={3}
        textAlign="center"
      >
        <Typography variant="h5" gutterBottom>
        {t('reset_password')}
        </Typography>

        <TextField
          fullWidth
          label={t('new_password')}
          type={showNewPassword ? 'text' : 'password'}
          value={newPassword}
          onChange={handlePasswordChange}
          margin="normal"
          required
          disabled={isSuccess}  // Disable field on success
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={toggleShowNewPassword} disabled={isSuccess}>
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LinearProgress
          variant="determinate"
          value={passwordStrength}
          sx={{ height: '5px', marginTop: '-8px', marginBottom: '16px' }}
        />

        <TextField
          fullWidth
          label={t('confirm_new_password')}
          type={showConfirmPassword ? 'text' : 'password'}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          margin="normal"
          required
          disabled={isSuccess}  // Disable field on success
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={toggleShowConfirmPassword} disabled={isSuccess}>
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {message && (
          <Typography
            variant="body2"
            color={messageType === 'error' ? 'error' : 'success'}
            gutterBottom
          >
            {message}
          </Typography>
        )}

        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading || !newPassword || !confirmPassword || isSuccess}  // Disable button on success
            fullWidth
          >
            {loading ? t('submitting_button') : t('submit_button')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PasswordReset;
