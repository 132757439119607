import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';
import ShareIcon from '@mui/icons-material/Share';
import { useTranslation } from 'react-i18next';

const ShareButton = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const currentUrl = window.location.href;

  // Share options array
  const shareOptions = [
    {
      name: 'WhatsApp',
      icon: <WhatsAppIcon fontSize="large" />,
      url: `https://wa.me/?text=${t('checkthisout')}${encodeURIComponent(currentUrl)}`,
    },
    {
      name: 'Twitter',
      icon: <TwitterIcon fontSize="large" />,
      url: `https://twitter.com/intent/tweet?url=${t('checkthisout')}${encodeURIComponent(currentUrl)}`,
    },
    {
      name: 'Facebook',
      icon: <FacebookIcon fontSize="large" />,
      url: `https://www.facebook.com/sharer/sharer.php?u=${t('checkthisout')}${encodeURIComponent(currentUrl)}`,
    },
    {
      name: 'SMS',
      icon: <SmsIcon fontSize="large" />,
      url: `sms:?&body=${t('checkthisout')}${encodeURIComponent(currentUrl)}`,
    },
    {
      name: 'Email',
      icon: <EmailIcon fontSize="large" />,
      url: `mailto:?subject=${t('checkthisout')}&body=${encodeURIComponent(currentUrl)}`,
    },
  ];

  // Toggle modal open/close
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      {/* Share Button */}
      {/* <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Share
      </Button> */}

      <Button
        variant="contained"
        color="primary"
        startIcon={<ShareIcon />}
        sx={{ textTransform: 'none' }}
        onClick={handleClickOpen}
      >
        {t('share')}
      </Button>

      {/* Share Options Modal */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>
          <Typography variant="h6" align="center" component="div">
            {t('share_this_page')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} justifyContent="center">
            {shareOptions.map((option, index) => (
              <Grid item xs={4} key={index} align="center">
                <IconButton
                  aria-label={option.name}
                  onClick={() => window.open(option.url, '_blank')}
                >
                  {option.icon}
                </IconButton>
                <Typography variant="body2">{option.name}</Typography>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ShareButton;
