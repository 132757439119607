import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Modal, Box, Typography, Button, Stack, Avatar, Paper, Grid, Divider, Chip, IconButton, Fade, Badge } from '@mui/material';
import { CircularProgress } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import DownloadIcon from '@mui/icons-material/Download'; // Importing the download icon
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EuroIcon from '@mui/icons-material/Euro';
import PeopleIcon from '@mui/icons-material/People';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import OrganizerDashboard from './OrganizerDashboard';
import { UserContext } from '../../contexts/UserContext';
import { useNotification } from '../../contexts/NotificationContext'; // Import the notification context


import ShareButton from '../../components/ShareButton'; // Adjust the import path based on where you save the component
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import PaymentPage from './PaymentPage';  // Import the PaymentPage component
import { Elements } from '@stripe/react-stripe-js';  // Import Elements
import { loadStripe } from '@stripe/stripe-js';

import ReportButton from '../../components/ReportButton';
import { getTranslatedCollectDateOptions } from '../../components/collectDateTranslations';

import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use'; // To make the confetti fit the window size


import { useTranslation } from 'react-i18next';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const ActivityDetails = () => {
    const { t } = useTranslation();

    const { showNotification } = useNotification(); // Fix: destructure correctly

    const { activityId } = useParams();
    const [activity, setActivity] = useState(null);
    const { userInfo } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [subscribedCount, setSubscribedCount] = useState(0);
    const [subscriptionId, setSubscriptionId] = useState(null);
    const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
    const [subscriptionCost, setSubscriptionCost] = useState(0);

    const [clientSecret, setClientSecret] = useState(null); // Add this state definition
    const [isLiked, setIsLiked] = useState(false);
    const [totalLikes, setTotalLikes] = useState(0);
    const [likeInProgress, setLikeInProgress] = useState(false); // To prevent multiple rapid clicks
    const [isPending, setIsPending] = useState(false);

    const [paymentStatus, setPaymentStatus] = useState(null);
    const [isAddToAgendaModalOpen, setAddToAgendaModalOpen] = useState(false);  // Modal for 'Add to Agenda'

    const { width, height } = useWindowSize(); // Hook to get window dimensions
    const [showConfetti, setShowConfetti] = useState(false);  // State to control confetti

    const translatedCollectDateOptions = getTranslatedCollectDateOptions(t);
    const [openQuitActivityModal, setOpenQuitActivityModal] = useState(false);


    // Function to get the translated label for the collect date
    const getTranslatedCollectDate = (collectDate) => {
        const option = translatedCollectDateOptions.find(opt => opt.value === collectDate);
        return option ? option.label : collectDate; // Fallback to original if not found
    };

    useEffect(() => {
        if (isAddToAgendaModalOpen) {
            setShowConfetti(true);  // Start confetti when modal opens
            const timer = setTimeout(() => {
                setShowConfetti(false);  // Stop confetti after 3 seconds
            }, 6000);  // 6s
            return () => clearTimeout(timer);  // Cleanup the timeout on unmount
        }
    }, [isAddToAgendaModalOpen]);

    useEffect(() => {
        const fetchActivity = async () => {
            try {
                const activityResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/activities/${activityId}`);

                if (!activityResponse.ok) {
                    throw new Error('Failed to fetch activity');
                }
                const activityData = await activityResponse.json();
                setActivity(activityData);

                if (userInfo && userInfo._id) {
                    try {
                        const subscriptionResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/subscriptions/${activityId}/status?userId=${userInfo._id}`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${userInfo.sessionToken}`,
                            },
                        });
                        const subscriptionData = await subscriptionResponse.json();
                        if (subscriptionResponse.ok) {
                            if (subscriptionData.isSubscribed) {
                                setIsSubscribed(subscriptionData.isSubscribed);
                                setSubscriptionId(subscriptionData.subscriptionId);
                                // Convert subscription cost from cents to euros
                                setSubscriptionCost(subscriptionData.subscriptionCost / 100);

                                // Set isPending and paymentStatus from the response
                                setIsPending(subscriptionData.isPending);
                                setPaymentStatus(subscriptionData.paymentStatus);
                            } else {
                                setIsSubscribed(false);
                                setSubscriptionId(null);
                                setSubscriptionCost(0);
                                setIsPending(false);
                                setPaymentStatus(null);
                            }
                        }
                    } catch (error) {
                        console.log("Error during subscription status fetch:", error);
                    }
                }

                setLoading(false);
            } catch (err) {
                console.error(err);
                setLoading(false);
            }
        };

        fetchActivity();
    }, [activityId, userInfo]);

    useEffect(() => {
        const fetchTotalLikes = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/activities/${activityId}/total-likes`);
                if (response.ok) {
                    const data = await response.json();
                    setTotalLikes(data.totalLikes);  // Update totalLikes in the frontend state
                } else {
                    console.error('Failed to fetch total likes');
                }
            } catch (error) {
                console.error('Error fetching total likes:', error);
            }
        };

        fetchTotalLikes();
    }, [activityId, userInfo]);

    useEffect(() => {
        const fetchLikeStatus = async () => {
            if (!userInfo || !userInfo._id) return;

            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/likes/${activityId}/like-status?userId=${userInfo._id}`, {
                    headers: {
                        Authorization: `Bearer ${userInfo.sessionToken}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setIsLiked(data.liked); // Set the isLiked state based on the backend response
                    setTotalLikes(data.totalLikes); // Also set the total likes
                } else {
                    console.error('Failed to fetch like status');
                }
            } catch (error) {
                console.error('Error fetching like status:', error);
            }
        };

        fetchLikeStatus();
    }, [activityId, userInfo]);

    const handleLike = async () => {
        if (!userInfo || !userInfo._id) {
            showNotification(t('nouser_info_like'), 'error');
            return;
        }

        if (likeInProgress) {
            // Prevent multiple rapid clicks
            return;
        }

        setLikeInProgress(true);

        try {
            // Optimistically update the UI
            const newIsLiked = !isLiked;
            const newTotalLikes = newIsLiked ? totalLikes + 1 : totalLikes - 1;
            setIsLiked(newIsLiked);
            setTotalLikes(newTotalLikes);

            // Make API request
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/likes/${activityId}/like`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.sessionToken}`,
                },
                body: JSON.stringify({ userId: userInfo._id }),
            });

            if (!response.ok) {
                // Revert if request fails
                setIsLiked(!newIsLiked);
                setTotalLikes(totalLikes);
                showNotification(t('like_failed'), 'error');
            }

        } catch (error) {
            console.error('Like Error:', error);
            // Revert changes if an error occurs
            setIsLiked(!isLiked);
            setTotalLikes(totalLikes);
        } finally {
            setLikeInProgress(false); // Allow new click attempts
        }
    };


    const handleSubscribe = async () => {
        if (!userInfo || !userInfo._id) {
            showNotification(t('nouser_info'), 'error');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/activities/${activityId}/subscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.sessionToken}`,
                },
                body: JSON.stringify({ userId: userInfo._id }),
            });

            const data = await response.json();

            if (response.ok) {
                setIsSubscribed(true);
                setSubscribedCount(prevCount => prevCount + 1);
                setSubscriptionId(data.subscription._id);
                setSubscriptionCost(data.cost);

                if (data.cost === 0) {
                    showNotification(t('successfully_joined'), 'success');
                    setPaymentStatus('paid');
                    setAddToAgendaModalOpen(true);  // Open 'Add to Agenda' modal
                }
            } else {
                // Handle 403 and other error statuses
                if (response.status === 403 && data.message) {
                    showNotification(data.message, 'error');  // Show the specific backend message
                } else {
                    showNotification(t('failed_to_subscribe'), 'error');
                }
            }
        } catch (error) {
            console.error('Subscription Error:', error);
            showNotification(t('server_error'), 'error');  // Display a server error if catch block is reached
        }
    };


    const confirmQuitActivity = () => {
        setOpenQuitActivityModal(true);
    };

    const handleUnsubscribe = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/activities/${activityId}/unsubscribe`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.sessionToken}`,
                },
                body: JSON.stringify({ userId: userInfo._id }),
            });

            if (response.ok) {
                setIsSubscribed(false);
                setSubscribedCount(prevCount => prevCount - 1);
                showNotification(t('successfully_unsubscribed'), 'success');
            } else {
                showNotification(t('failed_to_unsubscribe'), 'error');
            }
        } catch (error) {
            console.error('Unsubscribe Error:', error);
        } finally {
            setOpenQuitActivityModal(false);  // Ensure the modal is closed after action
        }
    };


    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }
    if (error) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    marginTop: '20px',
                }}
            >
                <ErrorOutlineIcon color="error" sx={{ fontSize: 50 }} />
                <Typography variant="h6" color="error" mt={2}>
                    {t('error')}: {error}
                </Typography>
            </Box>
        );
    }
    if (!activity) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    marginTop: '20px',
                }}
            >
                <SearchOffIcon color="disabled" sx={{ fontSize: 50 }} />
                <Typography variant="h6" mt={2}>
                    {t('no_activity_found')}
                </Typography>
            </Box>
        );
    }

    const currentUrl = window.location.href;

    const handleOpenPaymentModal = async () => {
        if (!subscriptionId || !activity.organizerStripeAccountId) {
            console.error('Missing subscriptionId or organizerStripeAccountId');
            showNotification(t('paymentModal_failed_payment'), 'error');
            return;
        }

        // Open the modal first
        setPaymentModalOpen(true);

        // Then fetch the payment intent after the modal is open
        await fetchPaymentIntent(); // Fetch the clientSecret when the modal is opened
    };


    const handleClosePaymentModal = () => {
        setPaymentModalOpen(false);
    };

    const fetchPaymentIntent = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/create-payment-intent`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.sessionToken}`,
                },
                body: JSON.stringify({
                    amount: Math.round(subscriptionCost * 100),  // Convert euros to cents by multiplying by 100
                    organizerStripeAccountId: activity.organizerStripeAccountId,
                    subscriptionId: subscriptionId
                }),
            });

            const data = await response.json();
            if (response.ok) {
                setClientSecret(data.clientSecret);  // Store the clientSecret for Stripe
            } else {
                console.error('Failed to create payment intent');
            }
        } catch (error) {
            console.error('Error fetching payment intent:', error);
        }
    };


    // Create the items data to pass to the PaymentPage
    const items = activity ? [
        {
            name: activity.title,
            quantity: 1,
            price: subscriptionCost,
            currency: 'EUR'
        }
    ] : [];

    const handlePaymentSuccess = () => {
        setIsSubscribed(true);          // Mark the user as subscribed
        setPaymentStatus('paid');       // Update the payment status to 'paid'
        setPaymentModalOpen(false);     // Close the payment modal
        setAddToAgendaModalOpen(true);  // Open the 'Add to Agenda' modal
    };

    const translatedCollectDate = getTranslatedCollectDate(activity.collectDate);


    return (
        <>

            {showConfetti && (
                <Confetti
                    style={{ zIndex: "99999" }}
                    width={width}
                    height={height}
                    numberOfPieces={200}  // Adjust the number of confetti pieces
                    recycle={false}  // Make sure confetti stops after 3 seconds
                    colors={['#1976d2']}  // All confetti pieces will be blue
                />
            )}

            <Box sx={{ p: { xs: 2, md: 4 }, maxWidth: '100%', mx: 'auto' }}>

                <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden', mb: 4 }}>
                    <Box
                        sx={{
                            height: { xs: 400, md: 600 },
                            backgroundImage: `url(${activity.imageUrl})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            position: 'relative',
                        }}
                    >
                        {/* Report Button at Top-Right */}
                        {/* Like Button at Top-Left */}
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 16,
                                left: 16, // Align to the top left
                            }}
                        >
                            {/* Like Button with Badge for Like Count */}
                            <Badge
                                badgeContent={totalLikes}
                                color="secondary"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                sx={{
                                    '.MuiBadge-badge': {
                                        transform: 'translate(-10%, 30%)',
                                        fontSize: '0.8rem',
                                        height: '18px',
                                        minWidth: '18px',
                                        padding: '0',
                                    },
                                }}
                            >
                                <IconButton onClick={handleLike} color="primary" sx={{ fontSize: '2.5rem' }}>
                                    {isLiked ? (
                                        <FavoriteIcon sx={{ fontSize: '3rem' }} />
                                    ) : (
                                        <FavoriteBorderIcon sx={{ fontSize: '3rem' }} />
                                    )}
                                </IconButton>
                            </Badge>
                        </Box>

                        {/* Report Button at Top-Right */}
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 16,
                                right: 16, // Align to the top right
                            }}
                        >
                            <ReportButton
                                type="activity"
                                targetId={activityId}
                                buttonText={t('report')}
                                buttonStyle="#ffffff"
                                onSuccess={() => console.log('Report submitted for activity!')}
                            />
                        </Box>


                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                py: 2,
                                px: 3,
                                backgroundImage: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
                                color: 'white',
                            }}
                        >
                            <Typography variant="h5" fontWeight="bold">
                                {activity.title}
                            </Typography>
                            {activity.status === 'canceled' && (
                                <Chip label={t('canceled')} color="error" size="medium" sx={{ mb: 2 }} />
                            )}
                            <Typography variant="body2" fontWeight="bold">{activity.locationName}</Typography>

                            {/* Stack for Chips and Buttons */}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
                                {/* Display Level and Sport as Chips */}
                                <Stack direction="row" spacing={1}>
                                    <Chip label={activity.level} color="primary" />
                                    <Chip label={activity.sport} color="secondary" />
                                </Stack>

                                {/* Like and Share Section */}
                                <Stack direction="row" spacing={2} alignItems="center">
                                    {/* Like Button with Badge for Like Count */}
                                    {/* <Badge
                                        badgeContent={totalLikes}
                                        color="secondary"
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        sx={{
                                            '.MuiBadge-badge': {
                                                transform: 'translate(-10%, 30%)',
                                                fontSize: '0.8rem',
                                                height: '18px',
                                                minWidth: '18px',
                                                padding: '0',
                                            },
                                        }}
                                    >
                                        <IconButton onClick={handleLike} color="primary" sx={{ fontSize: '2.5rem' }}>
                                            {isLiked ? (
                                                <FavoriteIcon sx={{ fontSize: '3rem' }} />
                                            ) : (
                                                <FavoriteBorderIcon sx={{ fontSize: '3rem' }} />
                                            )}
                                        </IconButton>
                                    </Badge> */}

                                    {/* Share Button */}
                                    <ShareButton />
                                </Stack>
                            </Stack>
                        </Box>
                    </Box>
                </Paper>





                <Paper elevation={2} sx={{ p: 2, mb: 3, borderRadius: 2 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <EventIcon fontSize="small" />
                                <Typography variant="body2" fontWeight="bold">{activity.date}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <AccessTimeIcon fontSize="small" />
                                <Typography variant="body2" fontWeight="bold">{activity.time} ({activity.duration} {t('hours')})</Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <EuroIcon fontSize="small" />
                                <Typography variant="body2" fontWeight="bold">{activity.cost}{t('currency_txt')}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <PeopleIcon fontSize="small" />
                                <Typography variant="body2"><strong>{activity.subscribed}/{activity.participants}</strong></Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <LocationOnIcon fontSize="small" />

                                <Typography variant="body2" fontWeight="bold">{activity.location}</Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <strong>{t('organized_by')}:</strong>
                            </Stack>
                            <Link to={`/profile/${activity.organizer}`} style={{ textDecoration: 'none', color: 'inherit' }}>

                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Avatar src={process.env.REACT_APP_BACKEND_URL + activity.organizerAvatar} sx={{ width: 20, height: 20 }} />
                                    <Typography variant="body2">
                                        {activity.organizerUsername}
                                    </Typography>
                                </Stack>
                            </Link>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography variant="body2" fontWeight="bold">{t('subscription_deadline')}: {translatedCollectDate}</Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography variant="body2">{activity.description}</Typography>
                            </Stack>
                        </Grid>

                    </Grid>
                </Paper>

                {userInfo && userInfo._id !== activity.organizer && activity.status !== 'canceled' && (
                    <Box mt={3}>
                        {isSubscribed ? (
                            <Stack spacing={2}>
                                {/* Check if the payment is already completed */}
                                {subscriptionCost > 0 && paymentStatus !== 'paid' && (
                                    <>
                                        <Typography variant="body1" color="textSecondary" align="center">
                                            {t('requires_fee_to_join')}
                                        </Typography>
                                        <Button variant="contained" color="success" fullWidth onClick={handleOpenPaymentModal}>
                                            {t('pay')} {t('currency_txt')}{subscriptionCost}
                                        </Button>
                                    </>
                                )}
                                {(paymentStatus === 'paid' || subscriptionCost === 0) && (
                                    <>
                                        <Typography variant="body1" color="textSecondary" align="center">
                                            {t('successfully_joined')}
                                        </Typography>
                                        <Button variant="outlined" color="secondary" fullWidth onClick={confirmQuitActivity}>
                                            {t('quit')}
                                        </Button>

                                    </>
                                )}
                            </Stack>
                        ) : (
                            <Stack spacing={2}>
                                {!isPending ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={!userInfo}
                                        onClick={handleSubscribe}
                                        sx={{ opacity: !userInfo ? 0.5 : 1 }}
                                    >
                                        {userInfo ? t('join_activity') : t('connect_to_join')}
                                    </Button>
                                ) : (
                                    <Typography variant="body1" color="textSecondary" align="center">
                                        {t('subscription_pending_payment')}
                                    </Typography>
                                )}
                            </Stack>
                        )}
                    </Box>
                )}



                {!userInfo && (
                    <Typography variant="body1" color="textSecondary" align="center" sx={{ color: "#1976d2", fontWeight: "bolder" }}>
                        {t('log_in_to_join')}
                    </Typography>
                )}



                {userInfo && userInfo._id === activity.organizer && activity.status !== 'canceled' && (
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="h5" gutterBottom>
                            {t('dashboard')}
                        </Typography>
                        <Divider sx={{ mb: 3 }} />
                        <OrganizerDashboard
                            activity={activity}
                            onUpdateActivity={() => showNotification(t('update_activity'), 'success')}
                            onSendNotification={(message) => showNotification(`${t('send_notification')}: ${message}`, 'success')}
                        />
                    </Box>
                )}

                <Modal
                    open={isPaymentModalOpen}
                    onClose={handleClosePaymentModal}
                    aria-labelledby="payment-modal-title"
                    aria-describedby="payment-modal-description"
                >
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', p: 4, borderRadius: 2, boxShadow: 24 }}>
                        {clientSecret ? (
                            // Pass clientSecret to Elements via the options prop
                            <Elements stripe={stripePromise} options={{ clientSecret }}>
                                <PaymentPage
                                    clientSecret={clientSecret}
                                    amount={subscriptionCost}
                                    items={items}
                                    onPaymentSuccess={handlePaymentSuccess}  // Pass the success callback
                                />
                            </Elements>
                        ) : (
                            <CircularProgress />
                        )}
                    </Box>
                </Modal>


                <Modal
                    open={isAddToAgendaModalOpen}
                    onClose={() => setAddToAgendaModalOpen(false)}
                    aria-labelledby="add-to-agenda-modal-title"
                    aria-describedby="add-to-agenda-modal-description"
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={isAddToAgendaModalOpen}>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                p: 4,
                                borderRadius: 4,
                                boxShadow: 24,
                                outline: 'none',
                                maxWidth: '90vw',
                                textAlign: 'center', // Centering all elements inside the modal
                            }}
                        >
                            <Typography
                                variant="h5"
                                component="h5"
                                sx={{ mb: 3, fontWeight: 600 }}
                            >
                                {t('activity_join_success')}
                            </Typography>
                            <br />
                            <Typography
                                id="add-to-agenda-modal-title"
                                variant="h6"
                                component="h5"
                                sx={{ mb: 3, fontWeight: 600 }}
                            >
                                {t('add_to_agenda')}
                            </Typography>

                            <Button
                                variant="contained"
                                component="a"
                                href={`${process.env.REACT_APP_BACKEND_URL}/api/activities/${activityId}/download-ics`}
                                download
                                startIcon={<DownloadIcon />} // Adding a download icon to the button
                                sx={{
                                    borderRadius: '50px', // Rounded pill-style button
                                    textTransform: 'none', // No uppercase for modern feel
                                    px: 4, // Padding for a larger button
                                    py: 1.5,
                                    mb: 3, // Add margin below the button
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Soft shadow for a modern look
                                    backgroundColor: '#1976d2', // Custom button color (primary blue)
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#1565c0', // Darker shade on hover
                                    },
                                }}
                            >
                                {t('download_ics_file')}
                            </Button>

                            <Divider sx={{ my: 3 }} />

                            <Grid container justifyContent="center">
                                <ShareButton /> {/* Assuming ShareButton has its own styling */}
                            </Grid>
                        </Box>
                    </Fade>
                </Modal>

                {/* Confirm Quit Activity Modal */}
                <Dialog
                    open={openQuitActivityModal}
                    onClose={() => setOpenQuitActivityModal(false)}
                >
                    <DialogTitle>{t('confirm_quit_activity')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{t('are_you_sure_quit_activity')}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenQuitActivityModal(false)} color="primary">
                            {t('cancel')}
                        </Button>
                        <Button onClick={handleUnsubscribe} color="error">
                            {t('confirm')}
                        </Button>
                    </DialogActions>
                </Dialog>

            </Box>

        </>

    );
};

export default ActivityDetails;

