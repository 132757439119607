import React, { useState, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Container, Dialog, DialogContent, IconButton, Box, Grid, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Header from './components/Header';
import Events from './components/Events';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import Account from './pages/account/Account'; // Import Account page
import Payments from './pages/payments/Payments'; // Import Payments page
import PasswordReset from './components/PasswordReset'; // Import the password reset page

import { NotificationProvider } from './contexts/NotificationContext';
import { UserProvider, UserContext } from './contexts/UserContext'; // Import UserContext
import PrivateRoute from './components/PrivateRoute'; // Import PrivateRoute
import AdminDashboard from './pages/admin/AdminDashboard'; // Import AdminDashboard
import AdminRoute from './components/AdminRoute'; // Import AdminRoute

import banner from './assets/banner.svg'; // Import the logo image
import Footer from './components/Footer';  // Import Footer
import MyActivities from './pages/myactivities/MyActivities'; // Import the MyActivities component
import ActivityDetails from './pages/activities/ActivityDetails'; // Import the new page component
import ActivityListPage from './pages/activities/ActivityListPage'; // Import the new component
import MyLikes from './pages/mylikes/MyLikes'; // Import the MyLikes component
import UserProfile from './pages/users/UserProfile'; // Import the new component
import VerifyEmail from './pages/register/VerifyEmail'; // Import the VerifyEmail page

import { useTranslation } from 'react-i18next';

function Home({ onRegisterOpen }) {
  const { t } = useTranslation();


  const { isLoggedIn } = useContext(UserContext); // Get login state from context

  return (
    <Container>
      <Grid container spacing={4} alignItems="center" style={{ marginBottom: '32px' }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
          {t('welcome')}
            
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
          {t('description')}
          </Typography>
          {!isLoggedIn && ( // Conditionally render the button based on login state
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ marginTop: '16px' }}
              onClick={onRegisterOpen}
            >
              {t('register_button')}
            </Button>
          )}
        </Grid>
        <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
          <img src={banner} alt="Build Ma Team banner" style={{ width: '100%', maxWidth: "400px", margin: "0 auto" }} />
        </Grid>
      </Grid>
      <Events />
    </Container>
  );
}

function App() {
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);

  const handleLoginOpen = () => setOpenLogin(true);
  const handleLoginClose = () => setOpenLogin(false);
  const handleRegisterOpen = () => setOpenRegister(true);
  const handleRegisterClose = () => setOpenRegister(false);

  return (
    <UserProvider> {/* Wrap your app inside UserProvider */}
      <NotificationProvider>
        <Router>
          <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Header */}
            <Header onLoginOpen={handleLoginOpen} onRegisterOpen={handleRegisterOpen} />

            {/* Main content */}
            <Box component="main" sx={{ flexGrow: 1 }}>
              <Routes>
                <Route path="/" element={<Home onRegisterOpen={handleRegisterOpen} />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/myactivities" element={<PrivateRoute><MyActivities /></PrivateRoute>} /> {/* New Route for My Activities */}
                <Route path="/mylikes" element={<PrivateRoute><MyLikes /></PrivateRoute>} />

                <Route path="/activity/:activityId" element={<ActivityDetails />} /> {/* New Route for Activity Details */}
                <Route path="/activities" element={<ActivityListPage />} />
                
                {/* Add this route for the public profile */}
                <Route path="/profile/:userId" element={<UserProfile />} /> {/* New Route for Public Profile */}
                <Route path="/reset-password" element={<PasswordReset />} /> {/* Add this route */}

                {/* Protect the account and payments route */}
                <Route path="/account" element={<PrivateRoute><Account /></PrivateRoute>} />
                <Route path="/payments" element={<PrivateRoute><Payments /></PrivateRoute>} /> 


                {/* Admin Route */}
                <Route path="/admin/dashboard" element={<AdminRoute><AdminDashboard /></AdminRoute>} />

              </Routes>
            </Box>

            {/* Login Modal */}
            <Dialog open={openLogin} onClose={handleLoginClose} fullWidth maxWidth="sm">
              <Box display="flex" justifyContent="flex-end" p={2}>
                <IconButton onClick={handleLoginClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <DialogContent>
                <Login onLoginSuccess={handleLoginClose} /> {/* Close the modal on success */}
              </DialogContent>
            </Dialog>

            {/* Register Modal */}
            <Dialog open={openRegister} onClose={handleRegisterClose} fullWidth maxWidth="sm">
              <Box display="flex" justifyContent="flex-end" p={2}>
                <IconButton onClick={handleRegisterClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <DialogContent>
                <Register onRegisterSuccess={handleRegisterClose} /> {/* Close the modal on success */}
              </DialogContent>
            </Dialog>

            {/* Footer */}
            <Footer />
          </Box>
        </Router>
      </NotificationProvider>
    </UserProvider>
  );
}

export default App;
