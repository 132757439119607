import React, { useEffect, useState, useContext } from 'react';
import { Container, Typography, CircularProgress, Box } from '@mui/material';
import { UserContext } from '../../contexts/UserContext';
import ActivityCard from '../../components/ActivityCard'; // Adjust path as necessary
import { useTranslation } from 'react-i18next';

function MyLikes() {
    const { t } = useTranslation();

    const { userInfo } = useContext(UserContext);
    const [likedActivities, setLikedActivities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchLikedActivities = async () => {
            if (!userInfo || !userInfo._id) {
                setLoading(false);
                setError(t('user_notauth'));
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/likes/${userInfo._id}/likes`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${userInfo.sessionToken}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch liked activities');
                }

                const data = await response.json();
                setLikedActivities(data); // Data is the list of liked activities
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchLikedActivities();
    }, [userInfo]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Container>
                <Typography variant="h4" gutterBottom>
                    {t('my_likes')}
                </Typography>
                <Typography variant="body1" color="error">
                    {error}
                </Typography>
            </Container>
        );
    }

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                {t('my_likes')}
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
                {t('liked_activities_list')}
            </Typography>

            {likedActivities.length === 0 ? (
                <Typography variant="body1" color="textSecondary">
                    {t('no_liked_activities')}
                </Typography>
            ) : (
                likedActivities.map((activity) => (
                    <ActivityCard key={activity._id} activity={activity} />
                ))
            )}
        </Container>
    );
}

export default MyLikes;
