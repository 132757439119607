// TermsModalTrigger.jsx
import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, Link, DialogContent, DialogActions, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const TermsModalTrigger = ({ buttonText }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Link component="button" onClick={handleOpen} color="inherit">
                {buttonText}
            </Link>


            <Dialog open={open} onClose={handleClose}>
                {/* Terms Modal */}
                <DialogTitle>{t('terms_page.title')}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" gutterBottom>
                        {t('terms_page.content.introduction')}
                    </Typography>

                    {/* Legal Mentions Section */}
                    <Typography variant="h6" gutterBottom>
                        {t('terms_page.content.legal_mentions.title')}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        {t('terms_page.content.legal_mentions.text')}
                    </Typography>

                    {/* User Agreement Section */}
                    <Typography variant="h6" gutterBottom>
                        {t('terms_page.content.user_agreement.title')}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        {t('terms_page.content.user_agreement.text')}
                    </Typography>

                    {/* Payment Policy Section */}
                    <Typography variant="h6" gutterBottom>
                        {t('terms_page.content.payment_policy.title')}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        {t('terms_page.content.payment_policy.text')}
                    </Typography>

                    {/* Refund Policy Section */}
                    <Typography variant="h6" gutterBottom>
                        {t('terms_page.content.refund_policy.title')}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        {t('terms_page.content.refund_policy.text')}
                    </Typography>

                    {/* Cancellation Policy Section */}
                    <Typography variant="h6" gutterBottom>
                        {t('terms_page.content.cancellation_policy.title')}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        {t('terms_page.content.cancellation_policy.text')}
                    </Typography>

                    {/* Organizer Responsibilities Section */}
                    <Typography variant="h6" gutterBottom>
                        {t('terms_page.content.organizer_responsibilities.title')}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        {t('terms_page.content.organizer_responsibilities.text')}
                    </Typography>

                    {/* User Responsibilities Section */}
                    <Typography variant="h6" gutterBottom>
                        {t('terms_page.content.user_responsibilities.title')}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        {t('terms_page.content.user_responsibilities.text')}
                    </Typography>

                    {/* Age Restrictions Section */}
                    <Typography variant="h6" gutterBottom>
                        {t('terms_page.content.age_restrictions.title')}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        {t('terms_page.content.age_restrictions.text')}
                    </Typography>

                    {/* Dispute Resolution Section */}
                    <Typography variant="h6" gutterBottom>
                        {t('terms_page.content.dispute_resolution.title')}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        {t('terms_page.content.dispute_resolution.text')}
                    </Typography>

                    {/* Intellectual Property Section */}
                    <Typography variant="h6" gutterBottom>
                        {t('terms_page.content.intellectual_property.title')}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        {t('terms_page.content.intellectual_property.text')}
                    </Typography>

                    {/* Data Privacy Section */}
                    <Typography variant="h6" gutterBottom>
                        {t('terms_page.content.data_privacy.title')}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        {t('terms_page.content.data_privacy.text')}
                    </Typography>

                    {/* Termination Section */}
                    <Typography variant="h6" gutterBottom>
                        {t('terms_page.content.termination.title')}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        {t('terms_page.content.termination.text')}
                    </Typography>

                    {/* Force Majeure Section */}
                    <Typography variant="h6" gutterBottom>
                        {t('terms_page.content.force_majeure.title')}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        {t('terms_page.content.force_majeure.text')}
                    </Typography>

                    {/* Limitation of Liability Section */}
                    <Typography variant="h6" gutterBottom>
                        {t('terms_page.content.liability.title')}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        {t('terms_page.content.liability.text')}
                    </Typography>

                    {/* Changes to the Terms Section */}
                    <Typography variant="h6" gutterBottom>
                        {t('terms_page.content.changes.title')}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        {t('terms_page.content.changes.text')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TermsModalTrigger;
