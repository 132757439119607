import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardMedia, Typography, Chip, Stack, Box, Avatar } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EuroIcon from '@mui/icons-material/Euro';
import PeopleIcon from '@mui/icons-material/People';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import { useTranslation } from 'react-i18next';

const MiniActivityCard = ({ activity }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate(`/activity/${activity._id}`);
    };

    return (
        <Card sx={{ mb: 2 }} onClick={handleCardClick}>
            <Box sx={{
                position: 'relative',
                height: 120, // Slightly smaller height to make it more compact
            }}>
                <CardMedia
                    component="img"
                    image={activity.imageUrl}
                    alt={activity.sport}
                    sx={{ height: 120, width: '100%' }}
                />
                <Box sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    p: 1,
                    color: 'white',
                    backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 80%)',
                }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                        <Chip icon={<SportsSoccerIcon />} label={activity.sport} color="primary" size="small" />
                        <Chip label={activity.level} color="secondary" size="small" />
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar src={process.env.REACT_APP_BACKEND_URL + activity.organizerAvatar} sx={{ width: 24, height: 24, mr: 1 }} />
                            <Typography variant="body2">{activity.organizerUsername}</Typography>
                        </Box>
                    </Stack>
                </Box>
            </Box>
            <CardContent sx={{ textAlign: "left" }}>
                <Typography gutterBottom variant="h6" component="div">
                    {activity.title}
                </Typography>

                <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
                    <EventIcon color="action" />
                    <Typography variant="body2" sx={{ flexGrow: 1 }}>{activity.date}</Typography>
                    <AccessTimeIcon color="action" />
                    <Typography variant="body2">{activity.time}</Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
                    <EuroIcon color="action" />
                    <Typography variant="body2" sx={{ flexGrow: 1 }}>{activity.cost}</Typography>
                    <PeopleIcon color="action" />
                    <Typography variant="body2">{activity.subscribed}/{activity.participants} {t('players')}</Typography>
                </Stack>

                {activity.status === 'canceled' && (
                    <Chip label={t('canceled')} color="error" size="small" sx={{ mt: 2 }} />
                )}
            </CardContent>
        </Card>
    );
};

export default MiniActivityCard;
