import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CircularProgress, Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

function VerifyEmail() {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false); // Set initial loading to false, as user will click to verify
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    // Extract the token from searchParams once
    const token = searchParams.get('token');

    const verifyEmail = async () => {
        setLoading(true); // Show loading spinner while verifying
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/verify-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token }),
            });

            const data = await response.json();

            if (response.ok) {
                setMessage(t('email_verified_success'));
            } else if (data.message === 'Account already verified.') {
                setMessage(t('account_already_verified'));
            } else {
                setError(true);
                setMessage(data.message || t('verification_failed'));
            }
        } catch (err) {
            setError(true);
            setMessage(t('verification_error'));
        } finally {
            setLoading(false);
        }
    };

    const handleNavigate = () => {
        navigate('/');
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh" flexDirection="column">
            {!message ? (
                <>
                    <Typography variant="h6" color="textSecondary" gutterBottom>
                        {t('click_to_verify_email')}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={verifyEmail}
                        disabled={!token || loading} // Disable button if no token or during loading
                    >
                        {loading ? <CircularProgress size={24} /> : t('verify_email')}
                    </Button>
                </>
            ) : (
                <>
                    <Typography variant="h6" color={error ? 'error' : 'primary'} gutterBottom>
                        {message}
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleNavigate}>
                        {t('find_activities')}
                    </Button>
                </>
            )}
        </Box>
    );
}

export default VerifyEmail;
