import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Box, CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../contexts/UserContext';

const TransfersPage = ({ userId, sessionToken }) => {
    const { t } = useTranslation();
    const { userInfo, refreshUserInfo } = useContext(UserContext);

    const [transferDetails, setTransferDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch the transfer data from backend
    useEffect(() => {
        const fetchTransfers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/${userId}/transfers`, {
                    headers: {
                        Authorization: `Bearer ${userInfo.sessionToken}`,
                    },
                });

                setTransferDetails(response.data.transferDetails); // Assuming the response contains { transferDetails: [...] }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching transfer details:', error);
                setError(t('error_fetching_transfers'));
                setLoading(false);
            }
        };

        fetchTransfers();
    }, [userId, sessionToken]);

    if (loading) {
        return (
            <Box sx={{ p: 3 }}>
                <CircularProgress />
                <Typography variant="body1">{t('loading_transfers')}</Typography>
            </Box>
        );
    }

    // No transfers message
    if (transferDetails.length === 0) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography variant="h5" gutterBottom>
                {t('no_transfers_available')}
                </Typography>
                <Typography variant="body1">
                {t('no_transfers_message')}

                </Typography>
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography variant="body1" color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('activity_name')}
                            </TableCell>
                            <TableCell>{t('event_date')}</TableCell>
                            <TableCell>{t('total_collected')}</TableCell>
                            <TableCell>{t('total_transferred')}</TableCell>
                            <TableCell>{t('pending_transfers')}</TableCell>
                            <TableCell>{t('collect_payments')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transferDetails.map((transfer, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <a href={`https://buildmateam.com/activity/${transfer.activityId}`} target="_blank" rel="noopener noreferrer" style={{ fontWeight: "bolder", fontDecoration: "none", color: "grey" }}>
                                        {transfer.activityName}
                                    </a>
                                </TableCell>
                                <TableCell>{transfer.eventDate}</TableCell>
                                <TableCell>{t('currency_txt')}{transfer.totalCollected}</TableCell>
                                <TableCell>{t('currency_txt')}{transfer.totalTransferred}</TableCell>
                                <TableCell>{transfer.pendingTransfers}</TableCell>
                                <TableCell>
                                    {transfer.canCollectPayments ? (
                                        <Typography variant="body1" color="primary">{t('collect_now')}</Typography>
                                    ) : (
                                        <Typography variant="body2">{t('can_collect_after')} {transfer.collectDateTime}</Typography>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default TransfersPage;
