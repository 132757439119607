import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

const EditActivityForm = ({ open, handleClose, activity, onUpdateActivity }) => {
    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        date: activity.date,
        time: activity.time,
        participants: activity.participants,
        location: activity.location,
        description: activity.description,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        onUpdateActivity(formData);  // Pass the updated data to the parent component
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="edit-activity-dialog"
            aria-describedby="edit-activity-form"
        >
            <DialogTitle id="edit-activity-dialog">{t('edit_activity_dialog_title')}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                <br />
                    <TextField
                        label={t('label_max_participants')}
                        name="participants"
                        type="number"
                        value={formData.participants}
                        onChange={handleChange}
                        fullWidth
                    />
                    <TextField
                        label={t('label_location')}
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        fullWidth
                    />
                    <TextField
                        label={t('label_description')}
                        name="description"
                        multiline
                        rows={3}
                        value={formData.description}
                        onChange={handleChange}
                        fullWidth
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('cancel_button')}</Button>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {t('save_changes_button')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditActivityForm;
