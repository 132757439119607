import React, { useState } from 'react';
import { Box, Tabs, Tab, Container } from '@mui/material';
import { Home as HomeIcon, Build as ManageActivitiesIcon, Description as ReportsIcon, People as UsersIcon } from '@mui/icons-material';
import Home from './components/Home';
import ManageActivities from './components/ManageActivities';
import ManageReports from './components/ManageReports';
import ManageUsers from './components/ManageUsers';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import MessageIcon from '@mui/icons-material/Message';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { useTranslation } from 'react-i18next';

const AdminDashboard = () => {
  const { t } = useTranslation();

  // State to manage the active tab
  const [selectedTab, setSelectedTab] = useState(0);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Render the content based on the selected tab
  const renderTabContent = () => {
    switch (selectedTab) {
      case 0:
        return <Home />;
      case 1:
        return <ManageActivities />;
      case 2:
        return <ManageReports />;
      case 3:
        return <ManageUsers />;
      default:
        return <Home />;
    }
  };

  return (
    <Container sx={{ p: { xs: 1, md: 4 } }}>
      {/* Tabs for Navigation */}
      <Box sx={{ width: '100%', bgcolor: 'background.paper', mt: 2 }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          variant="scrollable" // Allows scrolling for smaller screens
          scrollButtons="auto" // Show scroll buttons on small devices
        >
          <Tab icon={<QueryStatsIcon />} aria-label={t('home')} />
          <Tab icon={<EventAvailableIcon />} aria-label={t('manage_activities')} />
          <Tab icon={<MessageIcon />} aria-label={t('manage_reports')} />
          <Tab icon={<UsersIcon />} aria-label={t('manage_users')} />
        </Tabs>
      </Box>

      {/* Content of the Selected Tab */}
      <Box sx={{ mt: 4 }}>
        {renderTabContent()}
      </Box>
    </Container>
  );
};

export default AdminDashboard;
