// components/ManageReports.jsx
import React, { useEffect, useState } from 'react';
import { Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Select, MenuItem, Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Edit, Save, Visibility, Close, AddCircle, Done } from '@mui/icons-material';
import axios from 'axios';
import { useNotification } from '../../../contexts/NotificationContext'; // Import the notification context
import { useTranslation } from 'react-i18next';

const statusColors = {
  unread: '#f0f0f0',
  read: '#d0ebff',
  pending: '#fff4cc',
  terminated: '#f9d2d2',
  resolved: '#d2f5d3', // Green for 'resolved'
};

const statusIcons = {
  unread: <Visibility fontSize="small" />,
  read: <Visibility fontSize="small" />,
  pending: <Edit fontSize="small" />,
  terminated: <Close fontSize="small" />,
  resolved: <Done fontSize="small" />,
};

const ManageReports = () => {
  const { t } = useTranslation();

  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notes, setNotes] = useState({});
  const [statuses, setStatuses] = useState({});
  const [filter, setFilter] = useState('all'); // Filter by report type
  const [statusFilter, setStatusFilter] = useState('all'); // Filter by report status
  const [selectedReportNotes, setSelectedReportNotes] = useState(null); // For note dialog
  const [selectedReportMessage, setSelectedReportMessage] = useState(null); // For message dialog

  const sessionToken = localStorage.getItem('sessionToken');
  const { showNotification } = useNotification(); // Fix: destructure correctly


  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/reports`, {
          headers: { Authorization: `Bearer ${sessionToken}` },
        });
        setReports(response.data);
        setLoading(false);
      } catch (err) {
        setError(t('failed_to_fetch_reports'));
        setLoading(false);
      }
    };

    fetchReports();
  }, [sessionToken]);

  const handleStatusChange = async (reportId, newStatus) => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/admin/reports/${reportId}/status`, { status: newStatus }, {
        headers: { Authorization: `Bearer ${sessionToken}` },
      });
      setReports(prevReports => prevReports.map(report => report._id === reportId ? { ...report, status: newStatus } : report));
    } catch (err) {
      showNotification(t('failed_to_update_report_status'), 'success');
    }
  };

  const handleAddNote = async (reportId) => {
    const noteText = notes[reportId];
    if (!noteText) return;

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/reports/${reportId}/note`, { note: noteText }, {
        headers: { Authorization: `Bearer ${sessionToken}` },
      });
      setReports(prevReports => prevReports.map(report => report._id === reportId ? {
        ...report,
        note: [...report.note, { text: noteText, addedAt: new Date() }],
      } : report));
      setNotes({ ...notes, [reportId]: '' });
    } catch (err) {
      showNotification(t('failed_to_add_note'), 'success');
    }
  };

  const filteredReports = reports.filter(report => (filter === 'all' || report.type === filter) && (statusFilter === 'all' || report.status === statusFilter));

  if (loading) return <Typography>{t('loading')}</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ width: '100%', padding: '16px' }}>
      <Typography variant="h4" gutterBottom>
      {t('manage_reports')}
      </Typography>

      {/* Filter by report type and status */}
      <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
        <Grid item xs={6}>
          <Typography variant="h6">{t('filter_by_type')}</Typography>
          <Select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            fullWidth
            sx={{ minWidth: '150px' }}
          >
            <MenuItem value="all">{t('all')}</MenuItem>
            <MenuItem value="activity">{t('activity')}</MenuItem>
            <MenuItem value="user">{t('user')}</MenuItem>
            <MenuItem value="suggestion">{t('suggestion')}</MenuItem>
            <MenuItem value="issue">{t('issue')}</MenuItem>
            <MenuItem value="assistance">{t('assistance')}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">{t('filter_by_status')}</Typography>
          <Select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            fullWidth
            sx={{ minWidth: '150px' }}
          >
            <MenuItem value="all">{t('all')}</MenuItem>
            <MenuItem value="unread">{t('unread')}</MenuItem>
            <MenuItem value="read">{t('read')}</MenuItem>
            <MenuItem value="pending">{t('pending')}</MenuItem>
            <MenuItem value="terminated">{t('terminated')}</MenuItem>
            <MenuItem value="resolved">{t('resolved')}</MenuItem> {/* New status */}
          </Select>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="reports table">
          <TableHead>
            <TableRow>
              <TableCell>{t('status')}</TableCell> {/* Status icon column */}
              <TableCell>{t('user')}</TableCell>
              <TableCell>{t('type')}</TableCell>
              <TableCell>{t('target_id')}</TableCell>
              <TableCell>{t('actions')}</TableCell> {/* Renamed Notes column to Actions */}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredReports.map((report) => (
              <TableRow key={report._id} style={{ backgroundColor: statusColors[report.status] }}>
                <TableCell>{statusIcons[report.status]}</TableCell> {/* Status icon */}
                <TableCell>{report.userId?.username || report.userId._id}</TableCell>
                <TableCell>{report.type}</TableCell>
                <TableCell>{report.targetId}</TableCell> {/* Target ID field */}
                <TableCell>
                  <Box display="flex" flexDirection="column">
                    <Button variant="outlined" onClick={() => setSelectedReportNotes(report)} sx={{ mb: 1 }}>{t('notes')}</Button>
                    <Button variant="outlined" onClick={() => setSelectedReportMessage(report)} sx={{ mb: 1 }}>{t('message')}</Button>
                    <Select
                      value={statuses[report._id] || report.status}
                      onChange={(e) => handleStatusChange(report._id, e.target.value)} // Auto-save status
                      size="small"
                      fullWidth
                    >
                      <MenuItem value="unread">{t('unread')}</MenuItem>
                      <MenuItem value="read">{t('read')}</MenuItem>
                      <MenuItem value="pending">{t('pending')}</MenuItem>
                      <MenuItem value="terminated">{t('terminated')}</MenuItem>
                      <MenuItem value="resolved">{t('resolved')}</MenuItem> {/* New status */}
                    </Select>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Notes Modal */}
      {selectedReportNotes && (
        <Dialog open={!!selectedReportNotes} onClose={() => setSelectedReportNotes(null)} fullWidth>
          <DialogTitle>{t('notes_for_report')}</DialogTitle>
          <DialogContent>
            {selectedReportNotes.note.map((n, index) => (
              <Typography key={index} variant="body2" sx={{ mb: 1 }}>
                {n.text} ({new Date(n.addedAt).toLocaleString()})
              </Typography>
            ))}
            <TextField
              label="Add Note"
              value={notes[selectedReportNotes._id] || ''}
              onChange={(e) => setNotes({ ...notes, [selectedReportNotes._id]: e.target.value })}
              fullWidth
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSelectedReportNotes(null)} startIcon={<Close />}>{t('close')}</Button>
            <Button onClick={() => handleAddNote(selectedReportNotes._id)} startIcon={<AddCircle />} variant="contained">{t('add_note')}</Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Message Modal */}
      {selectedReportMessage && (
        <Dialog open={!!selectedReportMessage} onClose={() => setSelectedReportMessage(null)} fullWidth>
          <DialogTitle>{t('message_for_report')}</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {selectedReportMessage.message}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSelectedReportMessage(null)} startIcon={<Close />}>{t('close')}</Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default ManageReports;
