import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const PasswordResetRequest = ({ open, onClose }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/request-resetpass`, { email });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(t('error_occurred_try_again'));
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box display="flex" justifyContent="flex-end" p={2}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
        {t('reset_your_password')}
        </Typography>
        <TextField
          fullWidth
          label={t('email_address')}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          required
        />
        {message && (
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {message}
          </Typography>
        )}
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading || !email}
            fullWidth
          >
            {loading ? t('sending') : t('send_reset_link')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PasswordResetRequest;
