import React, { createContext, useState, useContext, useCallback } from 'react';
import { Snackbar, Alert } from '@mui/material';

// Create the context
const NotificationContext = createContext();

// Hook to use notifications easily in any component
export const useNotification = () => useContext(NotificationContext);

// Notification Provider to wrap the app
export const NotificationProvider = ({ children }) => {
  const [snackbarNotifications, setSnackbarNotifications] = useState([]); // For Snackbar notifications
  const [appNotifications, setAppNotifications] = useState([]); // For in-app notifications

  // Function to show Snackbar notification
  const showNotification = useCallback((message, type = 'info') => {
    const id = new Date().getTime(); // Unique ID for each notification
    setSnackbarNotifications((prev) => [...prev, { id, message, type }]);

    // Remove the notification after 5 seconds
    setTimeout(() => {
      setSnackbarNotifications((prev) => prev.filter((notification) => notification.id !== id));
    }, 5000);
  }, []);

  // Function to add a notification to the app's notification list
  const addAppNotification = useCallback((message, type = 'info', link = '') => {
    const id = new Date().getTime();
    setAppNotifications((prev) => [...prev, { id, message, type, link, read: false, createdAt: new Date() }]);
  }, []);

  // Function to mark a notification as read
  const markAsRead = useCallback((id) => {
    setAppNotifications((prev) =>
      prev.map((notification) => notification.id === id ? { ...notification, read: true } : notification)
    );
  }, []);

  // Provide both Snackbar and App Notifications to context
  return (
    <NotificationContext.Provider value={{ showNotification, addAppNotification, appNotifications, markAsRead }}>
      {children}

      {/* Render the Snackbar notifications */}
      {snackbarNotifications.map(({ id, message, type }) => (
        <Snackbar
          key={id}
          open={true}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Alert severity={type} onClose={() => setSnackbarNotifications((prev) => prev.filter((notif) => notif.id !== id))}>
            {message}
          </Alert>
        </Snackbar>
      ))}
    </NotificationContext.Provider>
  );
};

export { NotificationContext };
